<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="btn-ct">
        <el-button @click="func.add" type="primary" class="btn-add">{{ $t('g.added') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="ModuleName" :label="$t('rights.module_name')" width="200">
        </el-table-column>
        <el-table-column prop="Url" min-width="160" :label="$t('rights.module_address')">
        </el-table-column>
        <el-table-column width="120" :label="$t('expenses.status')">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 1 ? 'tag-success' : 'tag-fail'">
              {{ scope.row.Status == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="170" :label="$t('g.operation')">
          <template #default="scope">
            <!-- <el-tag @click="func.edit(scope.row)">{{ $t('g.edit') }}</el-tag>
            <el-tag class="btn-del" @click="func.del(scope.row)" type="warning">{{ $t('g.delete') }}</el-tag> -->
            <el-button @click="func.del(scope.row)" class="btn-delete" type="primary">{{ $t('g.delete') }}</el-button>
            <el-button @click="func.edit(scope.row)" class="btn-edit" type="primary" style="margin-left: 10px;">{{
              $t('g.edit')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="$t('tips')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('rights.module_name')">
            <el-input v-model="formData.curData.ModuleName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.module_address')">
            <el-input v-model="formData.curData.Url"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <!-- <el-form-item label="排序">
            <el-input v-model="formData.curData.Sort"></el-input>
          </el-form-item> -->
          <el-form-item :label="$t('rights.module_parent')">
            <el-select class="inp2" v-model="formData.curData.ModuleId" :placeholder="$t('g.choose')">
              <el-option v-for="item in formData.topModules" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogVisible = false">{{ $t('g.btn_cancel') }}</el-button>
          <el-button type="primary" @click="func.save()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'Rights',
  setup() {
    const t = i18n.global.t
    const tableData = [
    ]
    const render = {
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valDevId: '',
      valName: '',
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      topModules: [],
      valAddress: ''
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      edit(row) {
        formData.curData = Object.assign({}, row)
        formData.dialogVisible = true
      },
      async del(row) {
        try {
          await ElMessageBox.confirm(
            t('g.sure_delete'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          await axios.post("/admin/access/delete", {
            id: row.Id
          })
          func.loadTopModule()
          func.loadData()
        } catch (e) {
          console.log("del", e)
        }
      },
      async save() {
        const data = formData.curData
        let params = {
          module_name: data.ModuleName,
          module_id: data.ModuleId,
          action_name: data.ActionName,
          url: data.Url,
          type: data.Type,
          sort: data.Sort,
          description: data.Description,
          status: data.Status,
        }
        if (data.Id) {
          params.id = data.Id
          await axios.post("/admin/access/doEdit", params)
        } else {
          await axios.post("/admin/access/doAdd", params)
        }
        func.loadTopModule()
        func.loadData()
        formData.dialogVisible = false
      },
      add() {
        formData.curData = {
          Sort: 100,
          Status: 1,
          ModuleId: 0,
        }
        formData.dialogVisible = true
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadTopModule() {
        let res = await axios.get('/admin/access/add')
        const topModules = [{
          value: 0,
          label: t('rights.module_top')
        }]
        for (let item of res) {
          topModules.push({
            label: item.ModuleName,
            value: item.Id
          })
        }
        formData.topModules = topModules
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let res = await axios.get(`/admin/access`, {
            params: {}
          })
          formData.total = res.Total
          const tableData = []
          for (let item of res) {
            item.Top = "@"
            tableData.push(item)
            item.AccessItem.map((item2) => {
              item2.Top = "---" + item.ModuleName
              item2.ModuleName = "---" + item2.ModuleName
              tableData.push(item2)
            })
          }
          formData.tableData = tableData
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      //顶级模块列表 
      func.loadTopModule()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  .inp2 {
    width: 100%;
  }

  .btn-del {
    margin-left: 0.5rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: normal;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
