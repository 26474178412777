<template>
  <div class="content">
    <div id="map"></div>
    <div class="map-filter">
      <!-- <el-select class="e-select e-select-first" v-model="formData.valOperator" :placeholder="$t('g.choose')">
          <el-option
            v-for="item in operators"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
      </el-select> -->
      <el-cascader style="width: 210px;margin-right: 0.5rem;" :clearable="true" v-model="formData.valOperator"
        :props="formData.props" :options="cascade_options_direct" :placeholder="$t('decision.tips.agent_code')" />
      <el-select :placeholder="$t('decision.input_name')" popper-class="e-search" class="e-select"
        v-model="formData.search" filterable>
        <el-option v-for="item in formData.data" :key="item.id" :label="item.name" :value="item.id">
          <span class="option-custom">
            <span class="option-label">{{ item.name }}</span>
            <span class="option-address">{{ item.address }}</span>
          </span>
        </el-option>
      </el-select>
    </div>
    <div class="map-options">
      <el-radio v-model="formData.type" label="all">{{ $t('device.types.all') }}</el-radio>
      <el-radio v-model="formData.type" label="ebox">{{ $t('device.types.ebox') }}</el-radio>
      <el-radio v-model="formData.type" label="cbox">{{ $t('device.types.cbox') }}</el-radio>
      <el-radio v-model="formData.type" label="pile">{{ $t('device.types.pile') }}</el-radio>
      <el-radio v-model="formData.type" label="cpbox">{{ $t('device.types.cpbox') }}</el-radio>
      <el-switch v-model="formData.online" active-color="#13ce66">
      </el-switch>
      <span class="switch-txt switch-txt-online">{{ $t('device.statuses.online') }}</span>
      <el-switch v-model="formData.offline" active-color="#ff4949">
      </el-switch>
      <span class="switch-txt switch-txt-offline">{{ $t('device.statuses.offline') }}</span>
    </div>
    <div class="eq-box-ct" v-show="formData.showEQBox" @click="func.doHideEQBox">
      <div class="eq-box" @click.stop="">
        <div class="title">{{ formData.curData.name }}</div>
        <div class="item">
          <span class="label">{{ $t('device.online_status') }}:</span>
          <span
            :class="{ 'status': true, 'val': true, 'active': formData.curData.status === $t('device.statuses.online') }">{{ formData.curData.status }}</span>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.agent') }}:</span>
          <span class="val">{{ formData.curData.agentCode }}</span>
          <span class="label" v-if="formData.curData.deposit">{{ $t('device.deposit') }}:</span>
          <span class="val" v-if="formData.curData.deposit">{{ formData.curData.deposit }}</span>
        </div>
        <div class="item">
          <span class="label" v-if="formData.curData.chargeMoney">{{ $t('device.moneys.charge') }}:</span>
          <span class="val" v-if="formData.curData.chargeMoney">{{ formData.curData.chargeMoney }}/时</span>
          <span class="label" v-if="formData.curData.saveMoney">{{ $t('device.moneys.save') }}:</span>
          <span class="val" v-if="formData.curData.saveMoney">{{ formData.curData.saveMoney }}</span>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.site_name') }}:</span>
          <span class="val">{{ formData.curData.siteName }}</span>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}:</span>
          <span class="val">{{ formData.curData.devId }}</span>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.address') }}:</span>
          <span class="val">{{ formData.curData.address }}</span>
        </div>
        <div class="img-ct">
          <el-image :preview-src-list="formData.curData.imgs" class="img" v-for="(item, idx) in formData.curData.imgs"
            :key="idx" :src="item" />
        </div>
        <div id="chart" class="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEqs } from '@/data/Equipments'
import ClusterBubble from '@/data/ClusterBubble'
import { onMounted, watch, onBeforeUnmount, reactive } from 'vue'
import { getCoord } from '@/data/default'
import * as echarts from 'echarts'
import i18n from '@/data/i18n/index'
import { operators, cascade_options_direct } from '@/data/default'

export default {
  name: 'AnalysisDistributionPoints',
  setup() {
    const t = i18n.global.t
    const AMap = window.AMap
    let map = null
    let marker = null
    let markerCluster = null
    let markerGeometries = []
    let clusterBubbleList = []
    let myChart = null // 图表
    let allEQs = [] //所有设备
    const formData = reactive({
      type: 'all',
      search: '',
      data: [],
      online: true,
      offline: true,
      curData: {},
      valOperator: 0,
      showEQBox: false,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
    })
    const func = {}

    func.doHideEQBox = () => {
      formData.showEQBox = false
      formData.search = ''
      for (const idx in formData.data) {
        formData.data[idx].active = false
      }
      // 还原当前marker
    }
    const doShowEQBox = (id, focus = true) => {
      for (const idx in formData.data) {
        const item = formData.data[idx]

        if (item.id === id) {
          formData.curData = item
          formData.showEQBox = true
          formData.data[idx].active = true
          setChart(item.slotNum, item.slotNumEm, item.slotNumErr)
          if (focus) {
            map.setZoomAndCenter(12, [item.lng, item.lat])
            return
          }
          // 放大当前marker

        }
      }
    }
    const getDataById = (id) => {
      for (const item of formData.data) {
        if (item.id === id) {
          return item
        }
      }
      return {}
    }
    const dataFilter = () => {
      let typeId = 0
      if (formData.type != 'all') {
        if (formData.type == 'cpbox') {
          typeId = 4
        } else {
          typeId = formData.type === 'pile' ? 3 : (formData.type === 'ebox' ? 1 : 2)
        }
      }
      let res = []
      for (const item of allEQs) {
        if (item.type === typeId || typeId === 0) {
          if (formData.online && item.status === t('device.statuses.online')) {
            res.push(item)
          } else if (formData.offline && item.status === t('device.statuses.offline')) {
            res.push(item)
          }
        }
      }
      //查运营商
      if (formData.valOperator) {
        let newRes = []
        // for(const item of res){
        //   if(formData.valOperator == item.agentCode){
        //     newRes.push(item)
        //   }
        // }
        newRes = res.filter(item => item.CodePath.includes(formData.valOperator));
        res = newRes
      }
      // 更新地图标记
      const geometries = []
      for (const item of res) {
        const marker = new AMap.Marker({
          position: [item.lng, item.lat],
          clickable: true,
          extData: {
            id: item.id
          },
          icon: getMarkerIcon(item)
        })
        marker.on("click", (e) => {
          doShowEQBox(e.target.w.extData.id, false)
        })
        geometries.push(marker)
      }
      console.log("geometries", geometries)
      markerCluster.setMarkers(geometries)

      formData.data = res
    }

    watch([
      () => formData.type,
      () => formData.online,
      () => formData.offline,
      () => formData.valOperator,
    ], () => {
      dataFilter()
    })
    watch(() => formData.search, () => {
      if (formData.search) {
        doShowEQBox(formData.search)
      }
    })
    const setChart = (total = 0, em = 0, err = 0) => {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: t('device.status'),
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: total - em - err, name: t('device.sp_battery') },
              { value: em, name: t('device.sp_empty') },
              { value: err, name: t('device.sp_error') },
            ]
          }
        ]
      }
      myChart.setOption(option)
    }
    const initMap = async () => {
      const coord = await getCoord()
      const center = [coord.lng, coord.lat]
      // 初始化地图
      map = new AMap.Map('map', {
        zoom: 12, // 设置地图缩放级别
        // pitch: 40, // 设置地图俯仰角
        center, // 设置地图中心点坐标
        resizeEnable: true,
        mapStyle: 'amap://styles/f9db5bb4478d355b9c1fc70c756671c8',
        features: ['bg', 'road'],
      })
      // 点数据
      const geometries = []
      console.log("formData.data", formData.data)
      for (const item of formData.data) {
        const marker = new AMap.Marker({
          position: [item.lng, item.lat],
          clickable: true,
          extData: {
            id: item.id
          },
          icon: getMarkerIcon(item)
          // content:""
        })
        marker.on("click", (e) => {
          console.log("e1111", e)
          doShowEQBox(e.target.w.extData.id, false)
        })
        geometries.push(marker)
      }
      // 创建点聚合实例
      markerCluster = new AMap.MarkerClusterer(map, geometries, {
        gridSize: 80,
      })

    }
    const getMarkerIcon = (item) => {
      let name = ""
      let status = "online"
      if (item.status != t('device.statuses.online')) status = "offline";
      if (item.type == 1) {
        name = "box"
      } else if (item.type == 2) {
        name = "battery"
      } else if (item.type == 3) {
        name = "pile"
      } else {
        name = "cpbox"
      }
      const image = require('@/assets/img/' + name + '-' + status + '.png')
      const icon = new AMap.Icon({
        size: new AMap.Size(32, 41),
        image,
      });
      return icon
    }
    onMounted(async () => {
      allEQs = await getEqs()
      formData.data = Object.assign([], allEQs)
      dataFilter()
      const chartDom = document.getElementById('chart')
      myChart = echarts.init(chartDom)
    })
    initMap()
    onBeforeUnmount(() => {
      // 销毁地图，防止卡顿
      map.destroy()
      if (myChart) {
        myChart.dispose();
      }
    })

    return {
      formData,
      operators,
      func,
      cascade_options_direct
    }
  }
}
</script>
<style lang="scss">
.e-search .el-select-dropdown__item {
  height: 3.76rem;
  line-height: 1.625rem;

  .option-custom {
    .option-label {
      font-size: 0.875rem;
    }

    .option-address {
      font-size: 0.75rem;
      display: block;
      color: #909399;
    }
  }

  &.hover,
  .selected {
    .option-label {
      color: #409eff;
    }

    .option-address {
      color: #409eff99;
    }
  }
}
</style>
<style lang="scss" scoped>
.content {
  position: relative;
  height: 100%;
  padding: 0px;

  #map {
    width: 100%;
    // height: 100%;
    height: calc(100% - 30px);
  }
}

.map-filter {
  position: absolute;
  top: 15px;
  left: 12px;
  z-index: 9999;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0;

  .e-select {
    width: 210px;

    &.e-select-first {
      margin-right: 0.5rem;
    }
  }
}

.map-options {
  position: absolute;
  bottom: 2.6rem;
  right: 1.25rem;
  background: #fff;
  z-index: 9999;
  padding: 0.275rem 0.8rem;
  border-radius: 0.5rem;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .switch-txt {
    font-size: 0.875rem;
    margin-left: 0.625rem;
    color: #606266;
    font-weight: 500;

    &.switch-txt-online {
      margin-right: 1.25rem;
    }

    &.switch-txt-offline {}
  }
}

.eq-box-ct {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .eq-box {
    margin-left: 5.25rem;
    margin-top: 4rem;
    background-color: #fff;
    width: 20rem;
    border-radius: 0.3125rem;
    box-sizing: border-box;
    padding: 0.75rem;

    .chart {
      width: 14rem;
      height: 16rem;
      margin: 0 auto;
    }

    .title {
      font-size: 1.2rem;
      padding: 0.9375rem 0;
    }

    .item {
      text-align: left;
      margin-bottom: 0.625rem;
      font-size: 0.875rem;

      .label {
        font-weight: 500;
        margin-right: 0.5rem;

        &:nth-child(3) {
          margin-left: 3.125rem;
        }
      }

      .val {
        &.status {
          color: #F56C6C;
        }

        &.active {
          color: #67C23A;
        }
      }
    }

    .img-ct {
      display: flex;
      justify-content: space-between;

      :deep(img) {
        object-fit: cover;
      }

      .img {
        width: 6rem;
        height: 7rem;
        border-radius: 0.25rem;
      }
    }
  }
}
</style>
