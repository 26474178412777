<template>
  <div class="component">
    <div class="chart-box">
      <!-- <div class="header">
        <el-select size="small" v-model="formData.valCabinet" placeholder="请选择">
            <el-option
              v-for="item in formData.cabinets"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
      </div> -->
      <div :id="render.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'
export default {
  name: 'OrderCircle',
  props: {
    type: {
      type: String,
      default: 'ebox'
    },
    agentCode:[String,Number],
    cycle:{
      type:Number,
      default:1,
    },
    startAt:{
      type:Date,
      default:null
    }
  },
  setup (props, ctx) {
    const t = i18n.global.t
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    let text = ""
    let devType = 1
    switch(props.type){
       case 'ebox':
         text = t('chart.trends.ebox')
         devType = 3
         break
      case 'cbox':
        text = t('chart.trends.cbox')
        devType = 1
        break
      case 'pile':
        text = t('chart.trends.pile')
        devType = 2
        break
    }
    const id = 'chartid-circle-' + props.type
    const cabinets = [{
      id: 0,
      name: '所有电柜'
    }]
    cabinets.push(...[
      {
        name: '换电柜001',
        id: 1
      }, {
        name: '换电柜002',
        id: 2
      }, {
        name: '换电柜003',
        id: 3
      }
    ])
    const formData = reactive({
      valCabinet: 0,
      cabinets
    })
    const render = {
      id
    }
    const initChart = () => {
      const chartDom = document.getElementById(id)
      myChart = echarts.init(chartDom)
      loadData()
    }
    const loadData = async () => {
      if(!props.startAt)return
      const params = {
        startAt:props.startAt.getTime() / 1000,
        CodePath:props.agentCode || "",
        cycle:props.cycle,
        devType
      }
      let res = await axios.get("/admin/analyze/order/cycle",{
        params
      })
      const legend = [res.lastKey, res.curKey]
      const xAxis = []
      const data = [[], []]
      for (let i = 0; i < res.cur.length; i++) {
        xAxis.push(res.cur[i].Key)
        let lastItem = i >= res.last.length ? 0 : res.last[i].Val
        data[1].push(res.cur[i].Val)
        data[0].push(lastItem)
      }
      const option = {
        title: {
          text
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend,
          textStyle:{
            fontSize:16,
          }
        },
        grid: {
          top: '80',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: legend[0],
            type: 'line',
            markPoint: {
              data: [
                { type: 'max', name: t('chart.max_value') }
              ]
            },
            data: data[0]
          },
          {
            name: legend[1],
            type: 'line',
            markPoint: {
              data: [
                { type: 'max', name: t('chart.max_value') }
              ]
            },
            data: data[1]
          }
        ]
      }

      option && myChart.setOption(option)
    }

    watch(() => formData.valCabinet, () => {
      // 数值改变更新图表
    })

    onMounted(() => {
      initChart()
    })
    return {
      formData,
      render
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
