<template>
  <div class="component-2">
    <el-badge is-dot  type="danger" class="popup-ct"  @click.stop="show = !show">
      <el-icon class="icon">
        <ChatDotSquare  />
        <Bell />
        <!-- <Box v-else/> -->
      </el-icon>
    </el-badge>
    <div class="popup-box" v-show="show" @click.stop="show = true">
      <div class="title">{{render.title}}</div>
      <div class="body">
        <div class="empty-txt" v-if="!render.data.length">{{$t('common.no_content')}}</div>
        <div class="data-list" v-else>
          <div @click.stop="func.navTo(item.url.name,item.url.params)" class="item" v-for="(item,idx) in render.data" :key="idx">
            <div class="l1">
              <div class="title">
                <span>{{item.title}}</span>
                <span class="status" v-if="item.status">{{item.status}}</span>
              </div>
              <div class="time">{{item.time}}</div>
            </div>
            <div class="l2">{{item.content}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, reactive } from 'vue'
import { toDay2 } from '@/common/func'
import axios from "axios"
import { navTo } from '@/common/func'
import i18n from '@/data/i18n/index'
export default {
  name: 'HeaderPopup',
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const t = i18n.global.t
    const type = props.type
    const show = ref(false)
    const titles = [
      // '使用中的订单',
      // '当天异常订单',
      t('common.popup_titles.t0'),
      // '最新异常日志',
      t('common.popup_titles.t1'),
      t('common.popup_titles.t2')
    ]
    const moreRouterName = [
      // 'Home',
      'AfterSellFeedBack',
      // 'LogSystem',
      'LogSystem',
      '',
      ''
    ]
    let data = []
    const render = reactive({
      type,
      title: titles[type],
      more: moreRouterName[type],
      data
    })
    const func = {}
    const hideCpn = (e) => {
      show.value = false
    }
    func.navTo = (name, params = {}) => {
      console.log('navTo')
      hideCpn()
      navTo(name, params)
    }
    const loadData = async () => {
      if(type==0){
        const res = await axios.get('/admin/faq',{
          params:{
            page:1,
            newest:1
          }
        })
        let data = []
        for(let item of res.List){
            data.push({
              title: item.Username,
              time: toDay2(item.CreateAt),
              status: '',
              content: item.Question,
              url: {
                name: 'AfterSellFeedBack',
                params: {}
              }
            })
        }
        render.data = data
      }else if(type == 1){
        const res = await axios.get('/admin/log/dev',{
          params:{
            page:1,
            unRead:1
          }
        })
        let data = []
        for(let item of res.List){
            // console.log(JSON.stringify(item.Log));
            
            // const tmp = JSON.parse(item.Log)
            let  tmp = {};
            try{
              tmp = JSON.parse(item.Log)
            }catch{
              tmp['type_txt'] = item.Log;
            }
            data.push({
              title: item.DeviceName,
              time: toDay2(item.CreateAt),
              status: tmp.type_txt,
              content: item.DeviceAddress,
              url: {
                name: 'LogApi',
                params: {}
              }
            })
        }
        render.data = data
      }else if(type == 2){
        const res = await axios.get('/admin/order/get',{
          params:{
            page:1,
          }
        })
        let data = []
        for(let item of res.List){
            data.push({
              title: getOrderNameByType(item.Type),
              time: toDay2(item.CreatedAt),
              status: "",
              content: item.OrderSn,
              url: {
                name: '',
                params: {}
              }
            })
        }
        render.data = data
      }
    }
    const getOrderNameByType = (type)=>{
      switch(type){
        case 1:
          return t('bill.types.cbox')
        case 2:
          return t('bill.types.pile')
        case 3:
          return t('bill.types.ebox')
        case 4:
          return t('bill.types.rent')
        case 5:
          return t('bill.types.charge')
        case 6:
          return t('bill.types.combo')
        case 7:
          return t('bill.types.return')
      }
    }
    onMounted(() => {
      loadData()
      setInterval(()=>{
        loadData()
      },60000)
      document.body.addEventListener('click', hideCpn, true)
    })
    onBeforeUnmount(() => {
      document.body.removeEventListener('click', hideCpn, true)
    })
    return {
      render,
      func,
      show
    }
  }
}
</script>

<style lang="scss" scoped>
  .component-2{
    position: relative;
    .popup-ct{
      cursor: pointer;
      >i{
        font-size: 1.3625rem;
        color: #2c3e50;
      }
      &:hover{
        >i{
          color: #3f51b5;
        }
      }
    }
    
  }
</style>
