<template>
    <div class="box">
        <div id="myEchart3" ref="myEchart3" style="width:100%;height:100%"></div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted } from 'vue';
import i18n from '@/data/i18n/index'
const t = i18n.global.t


onMounted(() => {
    const myEchartDiv = document.getElementById('myEchart3')
    const myEchart = echarts.init(myEchartDiv)
    let option = {}
    option = {
        xAxis: {
            type: 'category',
            data: [t('bigScreen.online'), t('bigScreen.offline'), t('bigScreen.not_active')],
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#fff'
                },
                fontSize: func.fontSize(18)
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: {
                    color: '#fff'
                },
                fontSize: func.fontSize(18)
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#838383'
                }
            }
        },
        grid: {
            show: true,
            top: '10%',
            left: '5%',
            bottom: '5%',
            right: '10%',
            containLabel: true,
            // 设置折线图表格区域背景颜色
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        },
        series: [
            {
                data: [
                    {
                        value: 0,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: '#089ee1' },
                                    { offset: 0, color: '#05eaf4' }
                                ]
                            )
                        }
                    },
                    {
                        value: 0,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: '#ab4245' },
                                    { offset: 0, color: '#f9afbe' }
                                ]
                            )
                        }
                    },
                    {
                        value: 0,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [
                                    { offset: 1, color: 'gray' },
                                    { offset: 0, color: '#cfcece' }
                                ]
                            )
                        }
                    },
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    barBorderRadius: 5,
                    borderWidth: 1,
                    borderType: 'solid'
                }
            }
        ]
    };
    // myEchart.setOption(option)
    setTimeout(() => {
        myEchart.setOption(option)
    }, 300)
    window.addEventListener("resize", function (event) {
        myEchart.resize()
    })
})
const func = {
    fontSize(res) {
        const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = clientWidth / 1920;
        return res * fontSize;
    },
}

</script>
<style lang="scss" scoped>
.box {
    color: red;
    height: 100%;
}
</style>