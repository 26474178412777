<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.order_no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderNo"
            :placeholder="$t('openSlotsLit.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('alertRecord.type') }}</span>
          <el-input class="inp" size="small" v-model="formData.valfaultType"
            :placeholder="$t('alertRecord.type')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.dev_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName"
            :placeholder="$t('openSlotsLit.dev_id')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('openSlotsLit.site_name')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.exportAllExcel(1)" class="search btn-export" type="info">{{
          $t('openSlotsLit.export_details') }}</el-button>
        <el-button @click="func.exportAllExcel(0)" class="search btn-export" type="info">{{
          $t('openSlotsLit.order_export') }}</el-button>
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" @row-click="func.showDetails"
        class="el-table">
        <el-table-column prop="ClientCode" v-if="!isOperator()" :label="$t('alertRecord.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="StationName" width="120" :label="$t('alertRecord.site_name')">
        </el-table-column>
        <el-table-column prop="PhoneNumType" :label="$t('alertRecord.phone_num_type')" width="120">
          <template #default="scope">
            {{ scope.row.PhoneNumType == 0 ? $t('alertRecord.phone_num_type_one') : $t('alertRecord.phone_num_type_two')
            }}
          </template>
        </el-table-column>
        <el-table-column prop="DialPhoneNum" :label="$t('alertRecord.dial_phone')" width="130">
        </el-table-column>
        <el-table-column prop="PhoneNums" :label="$t('alertRecord.phones')" width="240">
        </el-table-column>
        <el-table-column prop="OrderNum" width="200" :label="$t('alertRecord.OrderNum')">
        </el-table-column>
        <el-table-column prop="Type" width="120" :label="$t('alertRecord.type')">
        </el-table-column>
        <el-table-column prop="Mac" width="150" :label="$t('alertRecord.mac')">
        </el-table-column>
        <el-table-column prop="DevID" width="250" :label="$t('alertRecord.dev_id')">
        </el-table-column>
        <!-- <el-table-column prop="Res" width="120" :label="$t('openSlotsLit.open_slots_result')">
          <template #default="scope">
              <span :style="{color:scope.row.Res == 'OK'?'green':'red'}">
              {{ scope.row.Res == 'OK' ? '成功' : scope.row.Res }}
              </span>
          </template>
        </el-table-column> -->

        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>

      </el-table>
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="60%">
      <el-descriptions border column="3">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.agent_code')" v-if="!isOperator()">{{ formData.curData.ClientCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.site_name')">{{ formData.curData.StationName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.phone_num_type')" width="130">
          {{ formData.curData.PhoneNumType == 0 ? $t('alertRecord.phone_num_type_one') :
            $t('alertRecord.phone_num_type_two')
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.dial_phone')">{{ formData.curData.DialPhoneNum
          }}</el-descriptions-item>

        <el-descriptions-item :label="$t('alertRecord.OrderNum')">{{ formData.curData.OrderNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.site_name')">{{ formData.curData.StationName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.type')">{{ formData.curData.Type }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.mac')">{{ formData.curData.Mac }}</el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.dev_id')">{{ formData.curData.DevID }}</el-descriptions-item>
        <el-descriptions-item></el-descriptions-item>
        <el-descriptions-item></el-descriptions-item>
        <el-descriptions-item :label="$t('alertRecord.phones')">{{ formData.curData.PhoneNums }}</el-descriptions-item>

      </el-descriptions>
      <el-table :data="formData.slots[formData.curData.OrderNum]" border style="margin-top:20px">
        <el-table-column prop="OrderNum" :label="$t('alertRecord.OrderNum')" width="190"></el-table-column>
        <el-table-column :label="$t('alertRecord.res')" width="100">
          <template #default="scope">
            <span :style="{ color: scope.row.Code == 'OK' ? '#67C23A' : '#E6A23C' }">{{ $t('alertRecord.res') }} : {{
              scope.row.Code == 'OK' ? $t('alertRecord.result.success') : scope.row.Code }}</span>

          </template>
        </el-table-column>
        <el-table-column prop="PhoneNum" :label="$t('alertRecord.phones')" width="120"></el-table-column>
        <el-table-column prop="CallId" :label="$t('alertRecord.call_id')"></el-table-column>
        <el-table-column prop="StatusCode" :label="$t('alertRecord.status_code')" width="80"></el-table-column>
        <el-table-column prop="createAtStr" :label="$t('alertRecord.create_time')"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="80px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" size="small" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.deposit2')">
            <el-input size="small" type="number" v-model="formData.curData.Deposit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('bill.settle_rate')">
            <el-input size="small" type="number" v-model="formData.curData.CountRate"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.longitude')">
            <el-input size="small" type="number" v-model="formData.curData.Longitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.latitude')">
            <el-input size="small" type="number" v-model="formData.curData.Latitude"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.img_dev')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgBox' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgBox" :src="formData.curData.ImgBox" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_site')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStore' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStore" :src="formData.curData.ImgStore" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_env')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStreet' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStreet" :src="formData.curData.ImgStreet" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import { toDay, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElLoading } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'alertRecord',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]

    const render = {
      statuses,
      operators,
      types: [
        {
          label: '全部类型',
          value: -1
        },
        {
          label: '全部仓',
          value: 0
        },
        {
          label: '全部空仓',
          value: 1
        },
        {
          label: '全部电池仓',
          value: 2
        },
        {
          label: '指定仓',
          value: 3
        },
      ],
      results: [
        {
          label: '全部',
          value: -1
        },
        {
          label: '成功',
          value: 'OK'
        },
        {
          label: '失败',
          value: 'FAIL'
        },
      ],
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valTypes: -1,
      valResult: -1,
      dialogVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      UpdateAtLast: {},//电池更新最后时间
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
    })
    const func = {
      showDetails(row) {
        func.expand(row, [1])
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      async exportAllExcel(type = 1) {
        let params = {
        }
        if (formData.valfaultType) {
          params.faultType = formData.valfaultType
        }
        if (formData.valOrderNo) {
          params.orderNum = formData.valOrderNo
        }
        if (formData.valCode) {
          params.agentCode = formData.valCode
        }
        if (formData.valDevName) {
          params.devId = formData.valDevName
        }
        if (formData.valSiteName) {
          params.siteName = formData.valSiteName
        }
        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        if (type) {
          params.exportType = type
        }
        let loading = ''
        if (params.exportType == 1) {
          loading = ElLoading.service({
            lock: true,
            text: t('openSlotsLit.loading'),
            background: 'rgba(0, 0, 0, 0.7)',
          })
        }
        // console.log(JSON.stringify(params));

        let res = await axios.get(`/admin/faultdial/export`, {
          params
        });
        // console.log(JSON.stringify(res));
        // return;
        let excleData = [[]];
        let wscols = [];
        if (params.exportType == 1) {
          setTimeout(() => {
            loading.close()
          }, 200)
          excleData = [[
            'ID',
            t('alertRecord.agent_code'),
            t('alertRecord.site_name'),
            t('alertRecord.phone_num_type'),
            t('alertRecord.dial_phone'),
            t('alertRecord.OrderNum'),
            t('alertRecord.type'),
            t('alertRecord.mac'),
            t('alertRecord.dev_id'),
            t('alertRecord.res'),
            t('alertRecord.phones'),
            t('alertRecord.call_id'),
            t('alertRecord.status_code'),
            t('g.create_at'),

          ]];
          wscols = [    // 每列不同宽度px
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 15 },
            { wch: 22 },
            { wch: 22 },
            { wch: 15 },
            { wch: 26 },
            { wch: 10 },
            { wch: 28 },
            { wch: 28 },
            { wch: 10 },
            { wch: 22 },
          ];
        } else {
          excleData = [[
            'ID',
            t('alertRecord.agent_code'),
            t('alertRecord.site_name'),
            t('alertRecord.phone_num_type'),
            t('alertRecord.dial_phone'),
            t('alertRecord.phones'),
            t('alertRecord.OrderNum'),
            t('alertRecord.type'),
            t('alertRecord.mac'),
            t('alertRecord.dev_id'),
            t('g.create_at'),
          ]];
          wscols = [    // 每列不同宽度px
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 15 },
            { wch: 22 },
            { wch: 22 },
            { wch: 15 },
            { wch: 22 },
            { wch: 26 },
            { wch: 22 },
          ];
        }

        // console.log(JSON.stringify(res),123456);
        // console.log(excleData);
        const dictType = [
          t('alertRecord.phone_num_type_one'),
          t('alertRecord.phone_num_type_two'),
        ]
        if (res && res.List) {
          for (let item of res.List) {
            let arr = [];
            if (params.exportType == 1) {

              arr = [
                item.OrderId,
                item.ClientCode,
                item.StationName,
                item.PhoneNumType == 0 ? t('alertRecord.phone_num_type_one') : t('alertRecord.phone_num_type_two'),
                item.DialPhoneNum,
                item.OrderSn,
                item.Type,
                item.Mac,
                item.DevID,
                item.Code == 'OK' ? t('alertRecord.result.success') : item.Code,
                item.PhoneNum,
                item.CallId,
                item.StatusCode,
                toDay(item.order_created_at),
              ]
              excleData.push(arr)

            } else {
              arr = [
                item.Id,
                item.ClientCode,
                item.StationName,
                item.PhoneNumType == 0 ? t('alertRecord.phone_num_type_one') : t('alertRecord.phone_num_type_two'),
                item.DialPhoneNum,
                item.PhoneNums,
                item.OrderNum,
                item.Type,
                item.Mac,
                item.DevID,
                toDay(item.CreatedAt),
              ]
              excleData.push(arr)
            }


          }
        }

        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, t('alertRecord.alarm_information_table') + '.xlsx');
      },
      toCounterTime,
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/faultdial/getresinfo?orderNum=" + row.OrderNum)
          // console.log(JSON.stringify(res));


          res.map(item => {
            item.createAtStr = toDay(item.CreatedAt)
          })
          formData.slots[row.OrderNum] = res
        }
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      onUploadSuccess(res) {
        // console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        // console.log('formData', formData)
        func.loadData()
      },
      clear() {
        formData.valTypes = -1;
        formData.valResult = -1;
        formData.valOrderNo = '';
        formData.valCode = '';
        formData.valfaultType = '';
        formData.valDevName = '';
        formData.valSiteName = '';
        formData.valTime = '';
        func.loadData()
      },
      rowDblClick(row, column, e) {
        // console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        // console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        // console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        try {
          const params = {
            id: formData.curData.Id,
            imgBox: formData.curData.ImgBox,
            imgStore: formData.curData.ImgStore,
            imgStreet: formData.curData.ImgStreet,
            status: formData.curData.Status,
            deposit: formData.curData.Deposit,
            countRate: formData.curData.CountRate,
            longitude: formData.curData.Longitude,
            latitude: formData.curData.Latitude,
          }
          if (formData.curData.password) {
            params.password = formData.curData.password
          }
          await axios.put('/admin/box', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valfaultType) {
            params.faultType = formData.valfaultType
          }
          if (formData.valOrderNo) {
            params.orderNum = formData.valOrderNo
          }
          if (formData.valCode) {
            params.agentCode = formData.valCode
          }
          if (formData.valDevName) {
            params.devId = formData.valDevName
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          // console.log(JSON.stringify(formData.valTime[1] + " 23:59:59"));

          // if (formData.valDevName) {
          //   params.devName = formData.valDevName
          // }
          // console.log(JSON.stringify(params));

          let res = await axios.get(`/admin/faultdial/get`, {
            params
          })
          // console.log(JSON.stringify(res),123456);
          // return
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt)
            // item.UpdateAtStr = toDay(item.UpdateAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem 1.25rem 0 1.25rem;
  // margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    padding-left: 2rem;
    // background-color: grey;

    // padding-top: 1rem;

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 8rem;

          &:first-child {
            width: 6rem;
          }
        }


      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;
      margin-top: 12px;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;

        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  .pagination {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    .title {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  // .el-table :deep .cell {
  //   white-space: pre-wrap !important;
  // }

  .el-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 32%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
  }
}

.line {
  height: 1px;
  width: 100%;
  background-color: #e2e2e2;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width:1300px) {
  .content {
    >.card-box {
      .inp-list {
        grid-template-columns: repeat(3, 32.2%);
      }
    }
  }
}

@media screen and (max-width:1030px) {
  .content {
    >.card-box {
      .inp-list {
        grid-template-columns: repeat(2, 48.8%);
      }
    }
  }
}

@media screen and (max-width:792px) {
  .content {
    >.card-box {
      .inp-list {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}
</style>
