<template>
    <div class="box">
        <div id="myEchart2" class="myEchart2" ref="myEchart2" style="height:100%">
            <div class="c1">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.InDev') }}</div>
                    <div class="up2">{{ batteryPercent.InDev }}%</div>
                    <div class="up3">{{ props.InDevCount }}</div>
                </div>
                <div class="bottom">
                    <div class="inner" :style="`width:${batteryPercent.InDev}%`"></div>
                </div>
            </div>
            <div class="c2">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.InMan') }}</div>
                    <div class="up2">{{ batteryPercent.InMan }}%</div>
                    <div class="up3">{{ props.InManCount }}</div>
                </div>
                <div class="bottom">
                    <div class="inner" :style="`width:${batteryPercent.InMan}%`"></div>
                </div>
            </div>
            <div class="c3">
                <div class="up">
                    <div class="up1">{{ $t('bigScreen.InIdle') }}</div>
                    <div class="up2">{{ batteryPercent.InIdle }}%</div>
                    <div class="up3">{{ props.InIdleCount }}</div>
                </div>
                <div class="bottom">
                    <div class="inner" :style="`width:${batteryPercent.InIdle}%`"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { Bottom } from '@element-plus/icons-vue';
import * as echarts from 'echarts'
import { onMounted, reactive, computed, defineProps, watch } from 'vue';
import i18n from '@/data/i18n/index'
const t = i18n.global.t
const props = defineProps({
    InDevCount: Number, //在机柜
    InIdleCount: Number,//空闲
    InManCount: Number,//在骑手
    TatolCount: Number,//电池总数

})
const batteryPercent = reactive({
    InDev: 0,
    InIdle: 0,
    InMan: 0,
})
watch(props, () => {
    if (props.TatolCount) {
        batteryPercent.InDev = Number(props.InDevCount / props.TatolCount * 100).toFixed(1)
        batteryPercent.InIdle = Number(props.InIdleCount / props.TatolCount * 100).toFixed(1)
        batteryPercent.InMan = Number(props.InManCount / props.TatolCount * 100).toFixed(1)
    }
})
onMounted(() => {
    let option = {}

})

</script>
<style lang="scss" scoped>
.box {
    color: #FFFFFF;
    height: 100%;
    width: 100%;

    .myEchart2 {
        padding: 0 .375rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .c1,
        .c2,
        .c3 {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            padding-bottom: .125rem;
            font-size: .235rem;

            >.up {
                display: flex;
                width: 90%;

                .up1 {
                    flex: 1;
                    min-width: 1rem;
                }

                .up2 {
                    flex: 3;
                    color: #7feeed;
                }

                .up3 {
                    flex: 5;
                    color: #7feeed;
                    text-align: right;
                }
            }

            >.bottom {
                height: .175rem;
                width: 100%;
                margin-top: .025rem;
                background-color: #093067;
                border-radius: .1625rem;

                >.inner {
                    width: 100%;
                    height: .175rem;
                    background-color: #04daf0;
                    background: linear-gradient(to right, #089ee1, #05eaf4);
                    border-radius: .1625rem;

                }
            }
        }

        .c2 {
            >.bottom {

                >.inner {
                    background: linear-gradient(to right, #ab4245, #f9afbe);
                }
            }
        }

        .c3 {
            >.bottom {

                >.inner {
                    background: linear-gradient(to right, gray, #cfcece)
                }
            }
        }
    }
}
</style>