<template>
  <div class="content">
    <div class="card-box">
      <div class="inp-list1">
        <div class="inp-left">
          <div class="item">
            <span class="label">{{ $t('g.operator') }}</span>
            <el-cascader class="inp" size="small" @change="func.OperatorChange" style="max-width: 200px;"
              :clearable="true" v-model="formData.Agentcode" :props="formData.props"
              :options="render.cascade_options_direct" />
          </div>
          <div class="item item_margin">
            <span class="label">{{ $t('g.date') }}</span>
            <el-date-picker class="inp" size="small" v-model="formData.valTime3" style="max-width: 200px;"
              value-format="YYYY-MM-DD" type="daterange" unlink-panels :range-separator="$t('g.to')"
              :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
            </el-date-picker>
            <!-- <el-button @click="func.get_ordergain" class="search" type="primary">{{ $t("g.search")
                }}</el-button> -->
          </div>
        </div>
        <el-button @click="func.get_ordergain_combostat()" class="search" type="primary">{{ $t('g.search')
          }}</el-button>
      </div>
      <div class="databox_title">{{ $t('statistics.revenue_statistics') }}</div>
      <div class="databox">
        <div class="databox_item">
          <div>{{ formData.orderData.TotalMoney }}</div>
          <!-- <el-statistic :value="outputValue"/> -->
          <div>{{ $t('statistics.total_revenue') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.YesterdayMoney }}</div>
          <div>{{ $t('statistics.yesterday_earnings') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.YesterdayOrderCount }}</div>
          <div>{{ $t('statistics.yesterday_revenue_orders') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.TodayMoney }}</div>
          <div>{{ $t('statistics.today_earnings') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.TodayOrderCount }}</div>
          <div>{{ $t('statistics.today_revenue_orders') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.YesterdayExorderCount }}</div>
          <div>{{ $t('statistics.yesterday_battery_replacement') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.orderData.TodayExorderCount }}</div>
          <div>{{ $t('statistics.today_battery_swapping') }}</div>
        </div>
        <div class="databox_item effect" :class="{ 'animate': formData.isAnimating }">
          <div>{{ formData.orderData.ResMoney }}</div>
          <div>{{ $t('statistics.income') }}</div>
        </div>
        <div class="databox_item effect" :class="{ 'animate': formData.isAnimating }">
          <div>{{ formData.orderData.ResOrderCount }}</div>
          <div>{{ $t('statistics.number_of_orders') }}</div>
        </div>
      </div>

      <!-- <div class="databox">
       
      </div> -->
      <div class="databox_title">{{ $t('statistics.cabinet_statistics') }}</div>
      <div class="databox">
        <div class="databox_item" @click="func.jump(1, { name: 'valAlert', val: 0 })">
          <div>{{ formData.cabinet.OnLineNum }}</div>
          <div>{{ $t('statistics.on_line') }}</div>
        </div>
        <div class="databox_item" @click="func.jump(1, { name: 'valAlert', val: 1 })">
          <div>{{ formData.cabinet.OffLineNum }}</div>
          <div>{{ $t('statistics.offline') }}</div>
        </div>
        <div class="databox_item" @click="func.jump(2, { name: 'SlotNumErr', val: 'descending' })">
          <div>{{ formData.cabinet.SlotErrNum }}</div>
          <div>{{ $t('statistics.cabinets_with_abnormal_compartments') }}</div>
        </div>
        <div class="databox_item" @click="func.jump(2, { name: 'SlotNumBan', val: 'descending' })">
          <div>{{ formData.cabinet.SlotBanNum }}</div>
          <div>{{ $t('statistics.Cabinets_with_prohibited_storage') }}</div>
        </div>
        <div class="databox_item" @click="func.jump(2, { name: 'BatExNum', val: 'ascending' })">
          <div>{{ formData.cabinet.NoExBatNum }}</div>
          <div>{{ $t('statistics.non_battery_cabinets') }}</div>
        </div>
        <div class="databox_item" @click="func.jump(2, { name: 'SlotNumEm', val: 'ascending' })">
          <div>{{ formData.cabinet.NoSlotNumEm }}</div>
          <div>{{ $t('statistics.empty_containers') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.cabinet.AllNum }}</div>
          <div>{{ $t('statistics.total') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.cabinet.AverPrice?.toFixed(2) }}</div>
          <div>{{ $t('statistics.average_revenue_of_cabinets') }}</div>
        </div>
      </div>
      <div class="databox_title">{{ $t('statistics.battery_statistics') }}</div>
      <div class="databox">
        <div class="databox_item">
          <div>{{ formData.stat.InIdleCount }}</div>
          <div>{{ $t('statistics.idle') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.stat.InDevCount }}</div>
          <div>{{ $t('statistics.number_of_cabinets') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.stat.InManCount }}</div>
          <div>{{ $t('statistics.in_the_rider') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.stat.OfflineCount }}</div>
          <div>{{ $t('statistics.offline_battery') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.stat.OnlineCount }}</div>
          <div>{{ $t('statistics.online_battery') }}</div>
        </div>
        <div class="databox_item">
          <div>{{ formData.stat.TatolCount }}</div>
          <div>{{ $t('statistics.total') }}</div>
        </div>
      </div>
      <div class="databox_flex">
        <div class="databox_title">{{ $t('statistics.user_statistics') }}</div>
      </div>
      <div class="databox">
        <div class="databox_item ">
          <div>{{ formData.combostat.Rent }}</div>
          <div>{{ $t('statistics.renting_valid_package') }}</div>
        </div>
        <div class="databox_item ">
          <div>{{ formData.combostat.OverTime }}</div>
          <div>{{ $t('statistics.be_overdue') }}</div>
        </div>
        <div class="databox_item ">
          <div>{{ formData.combostat.AbandonRent }}</div>
          <div>{{ $t('statistics.terminate_the_lease') }}</div>
        </div>
        <div class="databox_item effect" :class="{ 'animate': formData.isAnimating }">
          <div>{{ formData.combostat.NewRent }}</div>
          <div>{{ $t('statistics.new_lease') }}</div>
        </div>
        <div class="databox_item effect" :class="{ 'animate': formData.isAnimating }">
          <div>{{ formData.combostat.ReNewRent }}</div>
          <div>{{ $t('statistics.renewal_of_lease') }}</div>
        </div>
        <div class="databox_item effect" :class="{ 'animate': formData.isAnimating }">
          <div>{{ formData.combostat.ExchangeUsersCount }}</div>
          <div>{{ $t('statistics.number_of_battery_swapping_users') }}</div>
        </div>
      </div>
      <!-- <div class="databox_time">
        <el-date-picker class="inp" v-model="formData.valTime2" value-format="YYYY-MM-DD" type="daterange" unlink-panels
          :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
          :shortcuts="render.shortcuts">
        </el-date-picker>
        <el-button @click="func.getcombostat" class="search" type="primary" size="small">{{ $t("g.search")
          }}</el-button>
      </div> -->
      <div class="databox_flex2">
        <div class="databox_title">{{ $t('statistics.order_statistics') }}</div>
        <div class="opt_flex">
          <el-select v-model="formData.typeValue" class="mg_right">
            <el-option :key="1" :label="$t('statistics.cabinet_order_statistics')" :value="1" />
            <el-option :key="2" :label="$t('statistics.battery_replacement_orders')" :value="2" />
          </el-select>
          <el-date-picker class="inp" style="width: 210px;" v-model="formData.valTime" value-format="YYYY-MM-DD"
            type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
          <el-button @click="func.search" class="search" type="primary">{{ $t("g.search") }}</el-button>
        </div>
      </div>
      <div class="card-box data-table">
        <div class="header">
          <span v-show="formData.type == true" class="title2"> <span style="margin-right: 6px;">{{ $t('home.batteryReplace') }}</span>
          {{$t('g.Success')}}：{{ formData.AllSuccOrder }}，{{$t('g.Failed')}}：{{ formData.AllFailOrder }}，{{$t('g.total')}}：{{ formData.AllSuccOrder +
              formData.AllFailOrder }}</span>
          <div class="header-icons">
            <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
          </div>
        </div>
        <el-table @row-click="func.showDetails" :data="formData.tableData" style="width: 100%" show-summary
          v-show="formData.type == true">
          <el-table-column prop="SiteName" :label="$t('statistics.site_name')" width="260" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="DeviceId" :label="$t('statistics.device_ID')" width="380">
          </el-table-column>
          <el-table-column prop="SuccCount" :label="$t('g.Success')">
          </el-table-column>
          <el-table-column prop="FailCount" :label="$t('g.Failed')">
            <template #default="scope">
              <el-tag type="danger">{{ scope.row.FailCount }}</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="AllCount" label="总计" >
          </el-table-column> -->
        </el-table>
        <el-table :data="formData.tableData" style="width: 100%" v-show="formData.type == false">
          <el-table-column prop="UserId" :label="$t('statistics.user_id')" width="260">
          </el-table-column>
          <el-table-column prop="UserName" :label="$t('statistics.user_nickname')" width="380">
          </el-table-column>
          <el-table-column prop="UserMobile" :label="$t('statistics.phone_number')">
          </el-table-column>
          <el-table-column prop="Count" :label="$t('statistics.battery_replacements_num')">
          </el-table-column>
        </el-table>
      </div>
      <!-- <div class="pagination">
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.fail_page" :total="formData.total">
        </el-pagination>
      </div> -->
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.fail_page" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('statistics.failed_order_details')" v-model="formData.dialogDisplayVisible" width="80%" top="10vh" @close="func.dialog_close"
      :infinite-scroll-immediate="false" infinite-scroll-delay="500" align-center>
      <div class="fail_title">
        {{ $t("g.unit_num") }}:{{ formData.fail_total }}，{{
          $t("g.per_page")
        }}:{{ formData.pageFailSize }}
      </div>
      <el-table :data="formData.fail_list" style="width: 100%">
        <el-table-column prop="Nickname" :label="$t('statistics.nickname')" width="160">
        </el-table-column>
        <el-table-column prop="Mobile" :label="$t('statistics.number')" width="120">
        </el-table-column>
        <el-table-column prop="DeviceId" :label="$t('statistics.equipment_number')" width="260">
        </el-table-column>
        <el-table-column prop="DeviceSiteName" :label="$t('statistics.site_name')" width="180">
        </el-table-column>
        <el-table-column prop="FailReason" :label="$t('statistics.failure_reason')"> </el-table-column>
        <el-table-column prop="CreatedAt" :label="$t('g.create_at')" width="170">
        </el-table-column>
      </el-table>
      <div class="pagination pagination_top">
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChangeFail"
          :page-size="formData.pageFailSize" :current-page="formData.curFailPage" :total="formData.fail_total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, ref } from "vue";
import { shortcuts, updateOperators, operatorList, cascade_options_direct } from "@/data/default";
import { toDay, navTo } from "@/common/func";
import axios from "axios";
import { ElMessage, dayjs } from "element-plus";
import i18n from "@/data/i18n/index";
import { isOperator, isAdmin } from "@/data/token";
import { useRouter } from 'vue-router';
import { useTransition } from '@vueuse/core'
export default {
  name: "ExpensesAgents",
  setup() {
    const router = useRouter();
    const t = i18n.global.t;
    const tableData = [];
    const statuses = [
      {
        label: t("expenses.statuses.all"),
        value: 0,
      },
      {
        label: t("expenses.statuses.enabled"),
        value: 1,
      },
      {
        label: t("expenses.statuses.disabled"),
        value: 2,
      },
    ];

    const render = {
      statuses,
      updateStatuses: [
        {
          label: t("expenses.enable"),
          value: 1,
        },
        {
          label: t("expenses.disable"),
          value: 0,
        },
      ],
      shortcuts,
      operatorList,
      cascade_options_direct
    };
    const formData = reactive({
      tableData,
      typeValue: 1,
      curData: {},
      valTime: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valTime2: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valTime3: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valStatus: 0,
      dialogDisplayVisible: false,
      curPage: 1,
      curFailPage: 1,
      pageSize: 1,
      pageFailSize: 1,
      total: 10,
      fail_total: 10,
      valName: "",
      valCode: "",
      active: -1,
      combostat: {},
      orderData: {
        TotalMoney: 0
      },
      stat: {},
      DeviceId: "",
      fail_list: [],
      Agentcode: '',
      type: true,
      AllSuccOrder: 0,
      AllFailOrder: 0,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      cabinet: {},
      IsStartWith: 1,
      isAnimating: false
    });
    const func = {
      async showDetails(row, column) {
        formData.DeviceId = row.DeviceId;
        func.get_orderfail();
        // if(column&&column.label==t('g.operation')){
        //   return;
        // }
        // formData.tableData = []
        //   formData.curPage = page
        //   let params = {
        //       page,
        //   }
        //   if(formData.valTime){
        //     params.StartTime = Math.floor(Date.parse(formData.valTime[0]) / 1000)
        //     params.Endtime = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
        //   }
        //   let res = await axios.get(`/admin/device/orderfail`,{
        //     params
        //   })
        //   console.log(res,params)
        formData.dialogDisplayVisible = true;
        // formData.curData = row;
      },
      dialog_close() {
        formData.fail_total = 10;
        formData.pageFailSize = 1;
      },
      search() {
        if (formData.typeValue == 1) {
          formData.type = true
          func.loadData()
        } else {
          func.loadData2()
          formData.type = false
        }
      },
      jump(type, obj) {
        console.log('路由对象', type, obj)
        // navTo('EboxList',{ id: 123 })
        router.push({
          name: 'EboxList', state: {
            params: {
              type: type,
              value: {
                name: obj.name,
                val: obj.val
              }
            }
          }
        });
        // this.$router.push({ name: 'User', params: { id: 123 }})
      },
      OperatorChange() {
        formData.total = 10;
        formData.pageSize = 1;
        formData.typeValue = 1
        formData.type = true
        func.loadData();
        // func.getcombostat();
        func.get_ordergain_combostat()
        // func.get_ordergain();
        func.get_stat();
        func.get_cabinet()
      },
      pageChange(val) {
        if (formData.typeValue == 1) {
          func.loadData(val);
        } else {
          func.loadData2(val);
        }
      },
      pageChangeFail(val) {
        func.get_orderfail(val);
      },
      showAddDataDialog() {
        formData.curData = {};
        formData.dialogVisible = true;
      },
      async get_orderfail(page = 1) {
        formData.curFailPage = page;
        let params = {
          page,
          DeviceId: formData.DeviceId,
        };
        params.CodePath = formData.Agentcode
        if (formData.valTime) {
          params.StartTime = Math.floor(
            Date.parse(formData.valTime[0] + " 00:00:00") / 1000
          );
          params.EndTime = Math.floor(
            Date.parse(formData.valTime[1] + " 23:59:59") / 1000
          );
        }
        let res = await axios.get(`/admin/device/orderfail`, {
          params,
        });

        res.List.map((item) => {
          item.CreatedAt = toDay(item.CreatedAt);
        });
        formData.fail_total = res.Total;
        formData.fail_list = res.List;
        formData.pageFailSize = res.PageSize;
      },
      async updateData() {
        if (typeof formData.curData.Status == "undefined") {
          ElMessage({
            type: "error",
            message: t("device.please_choose_status"),
          });
          return;
        }
        try {
          const params = {
            id: formData.curData.Id,
            mchid: formData.curData.Mchid,
            isValid: formData.curData.IsValid,
          };
          if (formData.curData.password) {
            params.password = formData.curData.password;
          }
          await axios.put("/admin/agent", params);
        } catch (e) { }
        formData.dialogVisible = false;
        func.loadData(formData.curPage);
        //更新reactive数据
        updateOperators();
      },
      // async opt_active(num) {
      //   formData.active = num;
      //   func.getcombostat();
      // },
      async search2() {

      },
      async get_ordergain_combostat() {
        let params = {};
        params.CodePath = formData.Agentcode
        if (formData.valTime3) {
          params.StartAt = Math.floor(
            Date.parse(formData.valTime3[0] + " 00:00:00") / 1000
          );
          params.EndAt = Math.floor(
            Date.parse(formData.valTime3[1] + " 23:59:59") / 1000
          );
        }
        let res = await axios.get(`/admin/order/ordergain`, {
          params
        });
        formData.orderData = res;
        let opt = {};
        opt.CodePath = formData.Agentcode
        if (formData.valTime3) {
          opt.StartAt = Math.floor(
            Date.parse(formData.valTime3[0] + " 00:00:00") / 1000
          );
          opt.EndAt = Math.floor(
            Date.parse(formData.valTime3[1] + " 23:59:59") / 1000
          );
        }
        let res1 = await axios.get(`/admin/user/getcombostat`, {
          params:opt
        });
        formData.combostat = res1;
        formData.isAnimating = true
        setTimeout(() => {
          formData.isAnimating = false
        }, 10000)
      },
      async get_stat() {
        let params = {};
        params.CodePath = formData.Agentcode
        let res = await axios.get(`/admin/battery/getstat`, {
          params
        });
        formData.stat = res;
      },
      async get_cabinet() {
        let params = {};
        params.CodePath = formData.Agentcode
        let res = await axios.get(`/admin/box/getdevtypenum`, {
          params
        });
        formData.cabinet = res;
      },
      async get_ordergain() {
        let params = {};
        params.CodePath = formData.Agentcode
        if (formData.valTime3) {
          params.StartAt = Math.floor(
            Date.parse(formData.valTime3[0] + " 00:00:00") / 1000
          );
          params.EndAt = Math.floor(
            Date.parse(formData.valTime3[1] + " 23:59:59") / 1000
          );
        }
        let res = await axios.get(`/admin/order/ordergain`, {
          params
        });
        formData.orderData = res;
        formData.isAnimating = true
        setTimeout(() => {
          formData.isAnimating = false
        }, 10000)
      },
      async getcombostat() {
        let params = {};
        params.CodePath = formData.Agentcode
        if (formData.valTime3) {
          params.StartAt = Math.floor(
            Date.parse(formData.valTime3[0] + " 00:00:00") / 1000
          );
          params.EndAt = Math.floor(
            Date.parse(formData.valTime3[1] + " 23:59:59") / 1000
          );
        }

        let res = await axios.get(`/admin/user/getcombostat`, {
          params,
        });
        formData.combostat = res;
      },
      async loadData2(page = 1) {
        try {
          formData.tableData = [];
          formData.curPage = page;
          let params = {
            page,
          };
          params.CodePath = formData.Agentcode
          if (formData.valTime) {
            params.StartAt = Math.floor(
              Date.parse(formData.valTime[0] + " 00:00:00") / 1000
            );
            params.EndAt = Math.floor(
              Date.parse(formData.valTime[1] + " 23:59:59") / 1000
            );
          }
          let res = await axios.get(`/admin/user/getexboxuser`, {
            params,
          });
          if (!res) {
            formData.total = 0;
            return;
          }
          formData.total = res.Total;
          formData.tableData = res.List;
          formData.pageSize = res.PageSize;
          formData.fail_page = page
        } catch (e) {
          console.log("loadData", e);
        }
      },
      async loadData(page = 1) {
        try {
          formData.tableData = [];
          formData.curPage = page;
          let params = {
            page,
          };
          params.CodePath = formData.Agentcode
          if (formData.valTime) {
            params.StartTime = Math.floor(
              Date.parse(formData.valTime[0] + " 00:00:00") / 1000
            );
            params.EndTime = Math.floor(
              Date.parse(formData.valTime[1] + " 23:59:59") / 1000
            );
          }
          params.IsStartWith = formData.IsStartWith

          let res = await axios.get(`/admin/device/orderstat`, {
            params,
          });
          if (!res) {
            formData.total = 0;
            return;
          }
          formData.total = res.Total;
          // res.List.map((item)=>{
          //   item.CreateAtStr = toDay(item.CreateAt)
          // })
          formData.tableData = res.List;
          formData.pageSize = res.PageSize;
          formData.fail_page = page
          formData.AllSuccOrder = res.AllSuccOrder;
          formData.AllFailOrder = res.AllFailOrder;
        } catch (e) {
          console.log("loadData", e);
        }
      },
    };

    // const source = ref(0)
    // console.log(source.value.num,'1111')
    // const outputValue = useTransition(source, {
    //   duration: 1500,
    // });
    // setTimeout(()=>{
    //   source.value = 100000
    // },2000)

    onMounted(() => {
      func.loadData();
      // func.getcombostat();
      func.get_ordergain_combostat()
      // func.get_ordergain();
      func.get_stat();
      func.get_cabinet()
      //处理请求运营商列表的默认值设置
      // function get_opt (){
      //   return new Promise((resolve,reject)=>{
      //     resolve(operatorList);
      //   });
      // }
      // async function defaultAgent(){
      //   let name = await get_opt()
      //   console.log(name[0].value)
      // }
      // defaultAgent()
    });
    return {
      func,
      render,
      formData,
      isOperator,
      isAdmin
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

// @media screen and (max-width: 800px) {
//   .content {
//     >.card-box {
//       .inp-list {
//         >.item {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

.databox_title {
  font-size: 20px;
  // font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
}

.databox {
  display: flex;
  // margin-bottom: 15px;
  flex-wrap: wrap;
  justify-content: left;
}

.databox_item {
  width: 126px;
  background-color: #f7fafc !important;
  border: 1px solid #54a9ff;
  // color: #fff;
  border-radius: 5px;
  height: 60px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.effect {
  // border: solid 1px #8396b9;
  background-color: #8396b9 !important;
  border: 1px solid #8396b9;
  color: #fff;
  position: relative;
  overflow: hidden;
}


// .databox_item:not(:first-child) {
//   margin-left: 15px;
// }

.databox_con {
  display: flex;
  margin-bottom: 15px;
  margin-left: 362px;
}

.databox_flex {
  display: flex;
  align-items: center;

  //justify-content: space-between;
}

.databox_time {
  text-align: left;
  margin-bottom: 15px;
}

.databox_btn {
  padding: 6px 13px;
  border: solid 1px gray;
  cursor: pointer;
}

.databox_btn:not(:first-child) {
  margin-left: 15px;
}

.btn_active {
  border-color: rgb(64, 158, 255);
  color: rgb(64, 158, 255);
}

.databox_flex2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.search {
  margin-left: 20px;
  margin-bottom: 5px;
}

.btn-ct {
  .search {
    margin-bottom: 0px;
  }
}

.descriptions_magtop {
  margin-top: 30px;
}

.dialog_box {
  height: 650px;
  overflow-y: auto;
}

.fail_title {
  text-align: left;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 500;
}

.pagination_top {
  margin-top: 35px;
}

.mg_right {
  margin-right: 10px;
  width: 160px;
}

.opt_flex {
  display: flex;
  align-items: center;
  // padding-top: 60px;
  margin-bottom: 10px;
}

.title2 {
  font-size: 1rem;
  font-weight: 500;
}



@keyframes blink-animation {
  0% {
    border-color: #8396b9;
  }

  10% {
    border-color: transparent;
  }

  20% {
    border-color: #8396b9;
  }

  30% {
    border-color: transparent;
  }

  40% {
    border-color: #8396b9;
  }

  50% {
    border-color: transparent;
  }

  60% {
    border-color: #8396b9;
  }

  70% {
    border-color: transparent;
  }

  80% {
    border-color: #8396b9;
  }

  90% {
    border-color: transparent;
  }

  100% {
    border-color: #8396b9;
  }
}

.animate {
  animation: blink-animation 10s linear;
}


.inp-list1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.inp-left {
  display: flex;
  flex-wrap: wrap;

  >.item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .label {
      display: flex;
      justify-content: left;
      min-width: 60px;
    }

    :deep(.el-range-editor--small.el-input__inner) {
      flex: 1;
    }

    .inp {
      flex: 1;
    }
  }
}

.item_margin {
  margin-left: 15px;
}

@media screen and (max-width:850px) {
  .item_margin {
    margin-left: 0px;
  }
}



// .effect span {
// 		position: absolute;
// 	}

// 	.effect :nth-child(1) {
// 		top: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 2px;
// 		animation: run1 1s linear infinite;
// 		/* animation-delay: 0s;  */
// 		background: linear-gradient(to right, #fff, #acd, cyan);
// 	}

// 	.effect :nth-child(2) {
// 		top: 0;
// 		right: 0;
// 		height: 100%;
// 		width: 2px;
// 		animation: run2 1s linear infinite;
// 		/* animation-delay: 1s; 设置动画延迟加载时间*/
// 		background: linear-gradient(to bottom, #fff, #acd, cyan);
// 	}

// 	.effect :nth-child(3) {
// 		bottom: 0;
// 		left: 0;
// 		height: 2px;
// 		width: 100%;
// 		animation: run3 1s linear infinite;
// 		/* animation-delay: 2s; 设置动画延迟加载时间*/
// 		background: linear-gradient(to right, #fff, #acd, cyan);
// 	}

// 	.effect :nth-child(4) {
// 		top: 0;
// 		left: 0;
// 		height: 100%;
// 		width: 2px;
// 		animation: run4 1s linear infinite;
// 		/* animation-delay: 3s; 设置动画延迟加载时间 */
// 		background: linear-gradient(to top, #fff, #acd, cyan);
// 	}

// 	@keyframes run1 {
// 		from {
// 			transform: translateX(-100%)
// 		}

// 		to {
// 			transform: translateX(100%)
// 		}
// 	}

// 	@keyframes run2 {
// 		from {
// 			transform: translateY(-100%)
// 		}

// 		to {
// 			transform: translateY(100%)
// 		}
// 	}

// 	@keyframes run3 {
// 		from {
// 			transform: translateX(100%)
// 		}

// 		to {
// 			transform: translateX(-100%)
// 		}
// 	}

// 	@keyframes run4 {
// 		from {
// 			transform: translateY(100%)
// 		}

// 		to {
// 			transform: translateY(-100%)
// 		}
// 	}</style>
