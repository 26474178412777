<template>
  <div class="component">
    <div class="chart-box">
      <div class="header">{{formData.date}}</div>
      <div :id="id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'

export default {
  name: 'PropertiesRank',
  props:{
    agentCode:[String,Number],
    type:{
      type:String,
      default:"requecy-hide"
    },
    devType:{
      type:Number,
      default:0,
    },
    cycle:{
      type:Number,
      default:1,
    },
    startAt:{
      type:Date,
      default:null
    }
  },
  setup (props) {
    const t = i18n.global.t
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    const id = "chartid-properties-rank-"+props.type
    const formData = reactive({
      date:""
    })
    const initChart = () => {
      const chartDom = document.getElementById(id)
      myChart = echarts.init(chartDom)
      loadData()
    }
    const loadData = async () => {
      if(!props.startAt)return
      const params = {
        startAt:props.startAt.getTime() / 1000,
        cycle:props.cycle,
        devType:props.devType,
        CodePath:props.agentCode || "",
      }
      let text = ""
      switch(props.type){
        case "err":
          text = t('chart.devs_rank.err')
          params.type = 0
          break
        case "offline":
          text = t('chart.devs_rank.offline')
          params.type = 1
          break
        case "lock":
          text = t('chart.devs_rank.locked')
          params.type = 2
          break
        case "open":
          text = t('chart.devs_rank.open')
          params.type = 3
          break
        case "charge":
          text = t('chart.devs_rank.charge')
          params.type = 4
          break
        case "frequency-high":
          text = t('chart.devs_rank.frequence_high')
          params.type = 6
          break
        case "frequency-low":
          text = t('chart.devs_rank.frequence_low')
          params.type = 7
          break
        case "lack":
          text = t('chart.devs_rank.lack')
          params.type = 5
          break
      }
      let res = await axios.get("/admin/analyze/prop/rank",{
        params
      })
      if(params.cycle == 1){
        formData.date = props.startAt.getFullYear()
      }else{
        formData.date = props.startAt.getFullYear() + "-" + (props.startAt.getMonth() + 1)
      }
      const legend = [t('chart.unit_times')]
      const xAxis = []
      const data = []
      for (let i = 0; i < res.length; i++) {
        const str = res[i].DevId
        xAxis.push(str.substr(-4))
        data.push(res[i].Num)
      }
      const option = {
        title: {
          text
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend,
          textStyle:{
            fontSize:16,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: legend[0],
            type: 'bar',
            data
          }
        ]
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      id,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
