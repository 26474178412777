<template>
    <div class="content">
      <div id="map"></div>
      <div class="map-filter">
        <div :class="{active:formData.filterTab==0}" @click="func.filter(0)">
          总共电池230
        </div>
        
        <div :class="{active:formData.filterTab==1}" @click="func.filter(1)" >
          <img src="../../assets/img/battery-cabinet.png" class="img-battery-cabinet"/>
          在柜电池10
        </div>
        
        <div :class="{active:formData.filterTab==2}" @click="func.filter(2)">
          <img src="../../assets/img/battery-user.png" class="img-battery-user"/>
          已租电池220
        </div>
       
        <div :class="{active:formData.filterTab==3}" @click="func.filter(3)">
          <img src="../../assets/img/battery-offline2.png" class="img-battery-offline2"/>
          离线电池150
        </div>
      
        <div :class="{active:formData.filterTab==4}" @click="func.filter(4)">
          <img src="../../assets/img/battery-overdue.png" class="img-battery-overdue"/>
          逾期电池7
        </div>
      </div>
    </div>
</template>
<script>
import { getEqs } from '@/data/Equipments'
import ClusterBubble from '@/data/ClusterBubble'
import { onMounted, watch, onBeforeUnmount, reactive } from 'vue'
import { getCoord } from '@/data/default'
import * as echarts from 'echarts'
import i18n from '@/data/i18n/index'
import { operators } from '@/data/default'

export default {
  name: 'AnalysisDistributionPoints',
  setup () {
    const t  = i18n.global.t
    const AMap = window.AMap
    let map = null
    let marker = null
    let markerCluster = null
    let markerGeometries = []
    let clusterBubbleList = []
    let myChart = null // 图表
    let allEQs = [] //所有设备
    const formData = reactive({
      type: 'all',
      search: '',
      data: [],
      online: true,
      offline: true,
      curData: {},
      valOperator:0,
      showEQBox: false,
      filterTab:0
    })
    const func = {}
    func.filter = (idx)=>{
      console.log(JSON.stringify(idx));
       formData.filterTab = idx
    }
    func.doHideEQBox = () => {
      formData.showEQBox = false
      formData.search = ''
      for (const idx in formData.data) {
        formData.data[idx].active = false
      }
      // 还原当前marker
    }
    const doShowEQBox = (id, focus = true) => {
      for (const idx in formData.data) {
        const item = formData.data[idx]

        if (item.id === id) {
          formData.curData = item
          formData.showEQBox = true
          formData.data[idx].active = true
          setChart(item.slotNum,item.slotNumEm, item.slotNumErr)
          if (focus) {
            map.setZoomAndCenter(12,[item.lng,item.lat])
            return
          }
          // 放大当前marker

        }
      }
    }
    const getDataById = (id) => {
      for (const item of formData.data) {
        if (item.id === id) {
          return item
        }
      }
      return {}
    }
    const dataFilter = () => {
      let typeId = 0
      if(formData.type != 'all'){
        if(formData.type == 'cpbox'){
          typeId = 4
        }else{
          typeId = formData.type === 'pile' ? 3 : (formData.type === 'ebox' ? 1 : 2)
        }
      }
      let res = []
      for (const item of allEQs) {
        if (item.type === typeId || typeId === 0) {
          if (formData.online && item.status === t('device.statuses.online')) {
            res.push(item)
          } else if (formData.offline && item.status === t('device.statuses.offline')) {
            res.push(item)
          }
        }
      }
      //查运营商
      if(formData.valOperator){
        const newRes = []
        for(const item of res){
          if(formData.valOperator == item.agentCode){
            newRes.push(item)
          }
        }
        res = newRes
      }
      // 更新地图标记
      const geometries = []
      res = [{"id":372,"lat":22.6596177,"lng":114.001117,"type":1,"name":"如电12格换电柜","saveMoney":0,"chargeMoney":0,"status":"离线","address":"未同步","devId":"ECBJRD1212XX20240118D898","agentCode":"BJRD","siteName":"元芬站","slotNum":12,"slotNumErr":0,"slotNumEm":10,"deposit":0.01,"imgs":[],"tel":"","contacts":""}]
      for (const item of res) {
        const marker = new AMap.Marker({
          position: [item.lng,item.lat],
          clickable:true,
          extData:{
            id:item.id
          },
          icon:getMarkerIcon(item)
        })
        marker.on("click",(e)=>{
          doShowEQBox(e.target.w.extData.id, false)
        })
        geometries.push(marker)
      }
      console.log("geometries",geometries)
      markerCluster.setMarkers(geometries)

      formData.data = res
    }

    watch([
      () => formData.type,
      () => formData.online,
      () => formData.offline,
      () => formData.valOperator,
    ], () => {
      dataFilter()
    })
    watch(() => formData.search, () => {
      if (formData.search) {
        doShowEQBox(formData.search)
      }
    })
    const setChart = (total = 0,em = 0, err = 0) => {
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: t('device.status'),
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: total - em - err, name: t('device.sp_battery') },
              { value: em, name: t('device.sp_empty') },
              { value: err, name: t('device.sp_error') },
            ]
          }
        ]
      }
      myChart.setOption(option)
    }
    const initMap = async () => {
      const coord = await getCoord()
      const center = [coord.lng,coord.lat]
      // 初始化地图
      map = new AMap.Map('map', {
        zoom: 12, // 设置地图缩放级别
        // pitch: 40, // 设置地图俯仰角
        center, // 设置地图中心点坐标
        resizeEnable:true,
        mapStyle: 'amap://styles/f9db5bb4478d355b9c1fc70c756671c8',
        features: ['bg', 'road'],
      })
      // 点数据
      const geometries = []
      console.log("formData.data",formData.data)
      console.log(JSON.stringify(formData.data),276);
      formData.data = [{"id":372,"lat":22.6596177,"lng":114.001117,"type":1,"name":"如电12格换电柜","saveMoney":0,"chargeMoney":0,"status":"离线","address":"未同步","devId":"ECBJRD1212XX20240118D898","agentCode":"BJRD","siteName":"元芬站","slotNum":12,"slotNumErr":0,"slotNumEm":10,"deposit":0.01,"imgs":[],"tel":"","contacts":""}]
      for (const item of formData.data) {
        const marker = new AMap.Marker({
          position: [item.lng,item.lat],
          clickable:true,
          extData:{
            id:item.id
          },
          icon:getMarkerIcon(item)
          // content:""
        })
        marker.on("click",(e)=>{
          console.log("e1111",e)
          doShowEQBox(e.target.w.extData.id, false)
        })
        geometries.push(marker)
      }
      // 创建点聚合实例
      markerCluster = new AMap.MarkerClusterer(map,geometries,{
        gridSize:80,
      })
      
    }
    const getMarkerIcon = (item) => {
      let name = ""
      let status = "online"
      if(item.status!=t('device.statuses.online'))status="offline";
      if(item.type==1){
        name = "box"
      }else if(item.type==2){
        name = "battery"
      }else if(item.type==3){
        name = "pile"
      }else{
        name = "cpbox"
      }
      // const image = require('@/assets/img/'+name+'-'+status+'.png')
      const image = require('@/assets/img/battery-offline2.png')
      const icon = new AMap.Icon({
          size: new AMap.Size(32, 64),
          imageSize:new AMap.Size(32, 64),
          image,
      });
      return icon
    }
    onMounted(async () => {
      allEQs = await getEqs()
      // formData.data = Object.assign([],allEQs)
      formData.data = [{"id":372,"lat":22.6596177,"lng":114.001117,"type":1,"name":"如电12格换电柜","saveMoney":0,"chargeMoney":0,"status":"离线","address":"未同步","devId":"ECBJRD1212XX20240118D898","agentCode":"BJRD","siteName":"元芬站","slotNum":12,"slotNumErr":0,"slotNumEm":10,"deposit":0.01,"imgs":[],"tel":"","contacts":""}];
      dataFilter()
      const chartDom = document.getElementById('chart')
      myChart = echarts.init(chartDom)
    })
    initMap()
    onBeforeUnmount(() => {
      // 销毁地图，防止卡顿
      map.destroy()
      if(myChart){
        myChart.dispose();
      }
    })

    return {
      formData,
      operators,
      func
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
    position:relative;
    height: 92%;
    #map{
      width: 100%;
      height: calc(100%);
    }
    .map-filter{
      width:97%;
      height: 56px;
      font-size: 20px;
      background-color: #ffffff;
      position: absolute; 
      z-index: 10;
      top:0;
      margin: 10px 10px 0px 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
     
      >div{
        // background-color: #409dfe;
        // color:#fff;
        border: 3px solid #fff;
        border-radius: 10px;
        height: 50px;
        line-height: 50px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        &:hover{
          cursor: pointer; 
        }
        img{
          width:32px;
          height: 32px;
          margin-right: 5px;
        }
        .img-battery-cabinet{
          width:48px;
          height: 24px;
        }
        .img-battery-offline2{
          height: 32px;
        }
      }
      .active{
        // background-color: #409dfe;
        // color:#fff;
        border: 3px solid #409dfe;
      }
    }

  }
</style>