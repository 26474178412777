<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;" :clearable="true" @change="func.opt_agent"
            v-model="formData.valCode" :props="formData.props" :options="render.options" />
        </div>
        <div class="item">
          <span class="label">{{ $t('device.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('device.site_name')"></el-input>
        </div>

        <div class="item">
          <span class="label">{{ $t('device.operate_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.online_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valAlert" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.alerts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.device_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valBattaryId"
            :placeholder="$t('device.device_id')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.exportAllExcel()" class="btn-export" type="info">{{ $t('g.export') }}</el-button>
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" @expand-change="func.expand" style="width: 100%"
        @row-click="func.showDetails" :summary-method="func.getSummaries" show-summary @sort-change="func.sort"
        ref="tableRef">
        <el-table-column prop="Id" label="Id" width="80">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('device.name')" width="140">
        </el-table-column>
        <el-table-column prop="SiteName" :label="$t('device.site_name')" width="140">
        </el-table-column>
        <el-table-column prop="Address" :label="$t('device.address')" width="140">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')"
          :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.no')" width="260">
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="BatExNum" width="120" :label="$t('device.ebox.replaceableBattery')" sortable="custom">
        </el-table-column>
        <el-table-column prop="SlotNumErr" width="120" :label="$t('device.slot_num_error')" sortable="custom">
        </el-table-column>
        <el-table-column prop="SlotNumBan" width="120" :label="$t('device.ebox.disableBin')" sortable="custom">
        </el-table-column>
        <el-table-column prop="Temp" width="100" :label="$t('device.temp')" sortable="custom">
        </el-table-column>
        <el-table-column prop="SlotNumEm" width="100" :label="$t('device.slot_num_empty')" sortable="custom">
        </el-table-column>
        <el-table-column prop="SlotNum" width="100" :label="$t('device.slot_num_all')">
        </el-table-column>
        <el-table-column prop="MeasEnergy" width="100" :label="$t('device.electric_quantity')">
        </el-table-column>
        <el-table-column prop="SlotNumBattary" width="100" :label="$t('device.slot_num_bat')">
        </el-table-column>
        <el-table-column prop="Specs" min-width="200" :label="$t('device.specs')"> </el-table-column>
        <el-table-column prop="ModeNo" min-width="140" :label="$t('device.pattern')"> </el-table-column>
        <el-table-column prop="MacAddr" :label="$t('device.mac')" width="140">
        </el-table-column>
        <el-table-column prop="Deposit" width="120" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="CountRate" width="120" :label="$t('bill.settle_rate')">
        </el-table-column>
        <el-table-column width="100" :label="$t('device.alert')">
          <template #default="scope">
            <el-tag :class="scope.row.Alert == 0 ? 'tag-success' : 'tag-fail'">
              {{ scope.row.Alert == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('device.online_status')">
          <template #default="scope">
            <el-tag :class="scope.row.IsOnline == 0 ? 'tag-fail' : 'tag-success'">
              {{ scope.row.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.status')">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 1 ? 'tag-success' : 'tag-fail'">
              {{ scope.row.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.isrent')">
          <template #default="scope">
            <el-tag :class="scope.row.IsRent == 1 ? 'tag-brand' : 'tag-info'">
              {{ scope.row.IsRent == 1 ? $t('device.statuses.enrent') : $t('device.statuses.unrent') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.isreplaceable')">
          <template #default="scope">
            <el-tag :class="scope.row.IsReturn == 1 ? 'tag-brand' : 'tag-info'">
              {{ scope.row.IsReturn == 1 ? $t('device.statuses.replaceable') : $t('device.statuses.unreplaceable') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('member.is_release')" v-if="isAdmin()">
          <template #default="scope">
            <el-tag :class="scope.row.IsRelease == true ? 'tag-brand' : 'tag-info'">
              {{ scope.row.IsRelease == false ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="UpdateAtStr" min-width="180" :label="$t('g.update_at')">
        </el-table-column>
        <el-table-column :width="isEnVersion() ? 280 : 240" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-button @click="func.qrcode(scope.row)" class="btn-qrcode" type="primary">{{ $t('g.QRCode')
              }}</el-button>
            <el-button @click="func.rowDblClick(scope.row)" class="btn-edit" type="primary"
              style="margin-left: 10px;">{{ $t('g.edit')
              }}</el-button>
            <el-button @click="func.onOperate(scope.row)" class="btn-operate" type="primary"
              style="margin-left: 10px;">{{ $t('g.operation') }}</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.operation')" v-model="formData.dialogOperateVisible" align-center width="90%">
      <el-descriptions border column="4">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">
          {{ formData.curData.SiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.online_status')">
          <el-tag :class="formData.curData.IsOnline == 0 ? 'tag-fail' : 'tag-success'">
            {{ formData.curData.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">
          <el-tag :class="formData.curData.Status == 1 ? 'tag-success' : 'tag-fail'">
            {{ formData.curData.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('g.operation')">
          <el-button class="btn-delete" type="success" @click="func.restartDev(formData.curData.DevId)">{{
            $t('device.reset')
          }}</el-button>
          <el-button type="success" class="btn-operate" @click="func.openSlot(formData.curData.DevId, 0)">{{
            $t('device.open_all_slots')
          }}</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <el-table :data="formData.slots[formData.curData.Id]" border
        style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="DevId" :label="$t('device.slot_no')" width="60"></el-table-column>
        <el-table-column prop="BatId" :label="$t('device.device_id')" width="230"></el-table-column>
        <el-table-column prop="Voltage" :label="$t('device.voltage')" width="80">
          <template #default="scope">
            {{ scope.row.Voltage / 10 + 'V' }}
          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.current')" width="80">
          <template #default="scope">
            {{ scope.row.Current / 10 + 'A' }}
          </template>
        </el-table-column>
        <el-table-column prop="Soc" label="SOC" width="80"></el-table-column>
        <el-table-column prop="AlertInfo" :label="$t('device.ebox.faultCodes')"></el-table-column>
        <!-- <el-table-column label="故障中文名" width="110">
          <template #default="scope">
            {{ render.errorEnToCh[scope.row.AlertInfo] || '' }}
          </template>
        </el-table-column> -->
        <el-table-column prop="Remark" :label="$t('order.remark')" width="100"></el-table-column>
        <el-table-column :label="$t('device.ebox.enabledState')" width="100">
          <template #default="scope">
            <span :style="{ color: scope.row.Status == 0 ? '#E6A23C' : '#67C23A' }">
              {{ scope.row.Status ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="slot_status" :label="$t('device.ebox.switchStatus')" width="90">
          <template #default="scope">
            <span :style="{ color: scope.row.DoorStatus == 1 ? '#E6A23C' : '#67C23A' }">{{ scope.row.DoorStatus == 1 ?
              $t('g.open') : $t('g.close')
              }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="UpdateAtStr" :label="$t('g.update_at')" width="180"></el-table-column> -->
        <el-table-column label="操作" :width="isEnVersion() ? 480 : 370">
          <template #default="scope2">

            <el-button type="success" class="btn-qrcode" @click="func.addworkOrder(scope2.row)">{{
              $t('device.createWorkOrder') }}</el-button>
            <el-button type="success" class="btn-add"
              @click="func.slotRestart(formData.curData.DevId, scope2.row.DevId)">{{
                $t('device.slotRestart') }}</el-button>
            <el-button type="info" :class="scope2.row.Status ? 'btn-edit' : 'tag-fail'"
              @click="func.toggleSlotStatus(scope2.row)">{{
                scope2.row.Status ? $t('device.statuses.enabled') :
                  $t('device.statuses.disabled') }}</el-button>
            <el-button type="success" class="btn-operate"
              @click="func.openSlot(formData.curData.DevId, scope2.row.DevId)">{{ $t('device.open_slot') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="100%" align-center>
      <el-descriptions border column="4">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">
          {{ formData.curData.SiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item v-if="!isOperator()" :label="$t('g.agent_code')">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.levelname')">{{ formData.curData.AgentLevel }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.specs')">{{ formData.curData.Specs }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.pattern')">{{ formData.curData.ModeNo }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.mac')">
          {{ formData.curData.MacAddr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.coordinate')">
          {{ formData.curData.Longitude }},{{ formData.curData.Latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">
          {{ formData.curData.Address }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.Deposit }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.settle_rate')">{{ formData.curData.CountRate }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.alert')">
          <el-tag :class="formData.curData.Alert == 0 ? 'tag-success' : 'tag-fail'">
            {{ formData.curData.Alert == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.online_status')">
          <el-tag :class="formData.curData.IsOnline == 0 ? 'tag-fail' : 'tag-success'">
            {{ formData.curData.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">
          <el-tag :class="formData.curData.Status == 1 ? 'tag-success' : 'tag-fail'">
            {{ formData.curData.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.isrent')"> <el-tag
            :class="formData.curData.IsRent == 1 ? 'tag-brand' : 'tag-info'">{{ formData.curData.IsRent == 1 ?
              $t('device.statuses.enrent') : $t('device.statuses.unrent') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.isreplaceable')"> <el-tag
            :class="formData.curData.IsReturn == 1 ? 'tag-brand' : 'tag-info'">{{ formData.curData.IsReturn == 1
              ?
              $t('device.statuses.replaceable') : $t('device.statuses.unreplaceable') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('member.is_release')" v-if="isAdmin()"> <el-tag
            :class="formData.curData.IsRelease == true ? 'tag-brand' : 'tag-info'">{{ formData.curData.IsRelease
              ==
              false ? $t('g.no') : $t('g.yes') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.life_time')">
          {{ func.toCounterTime(formData.curData.LifeTime) }}</el-descriptions-item>

        <el-descriptions-item :label="$t('device.temp')">
          {{ formData.curData.Temp }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.meas_power')">
          {{ formData.curData.MeasPower }}W</el-descriptions-item>
        <el-descriptions-item :label="$t('device.electric_quantity')">
          {{ formData.curData.MeasEnergy }}KWH</el-descriptions-item>
        <el-descriptions-item :label="$t('device.electric_price')">
          {{ formData.curData.EnergyPrice }}元</el-descriptions-item>
        <el-descriptions-item :label="$t('device.ebox.minPower')">
          {{ formData.curData.swapSoc }}%</el-descriptions-item>
        <el-descriptions-item :label="$t('device.ebox.keepEmptyNum')">
          {{ formData.curData.emSlotMinNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.ebox.keepBatteryNum')">
          {{ formData.curData.batMinNum }}</el-descriptions-item>

        <el-descriptions-item :label="$t('device.img_dev')" v-if="formData.curData.ImgBox">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgBox"
            :preview-src-list="[formData.curData.ImgBox, formData.curData.ImgStore, formData.curData.ImgStreet]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.img_site')" v-if="formData.curData.ImgStore">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgStore"
            :preview-src-list="[formData.curData.ImgStore, formData.curData.ImgBox, formData.curData.ImgStreet]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.img_env')" v-if="formData.curData.ImgStreet">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgStreet"
            :preview-src-list="[formData.curData.ImgStreet, formData.curData.ImgBox, formData.curData.ImgStore]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_all')">
          {{ formData.curData.SlotNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_empty')">
          {{ formData.curData.SlotNumEm }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_error')">
          {{ formData.curData.SlotNumErr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.update_device_last')">
          {{ formData.UpdateAtLast.BatId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator_ownership')">
          {{ formData.curData.NamePath }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">
          {{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.update_time')">
          {{ formData.curData.UpdateAtStr }}</el-descriptions-item>


      </el-descriptions>
      <el-table :data="formData.slots[formData.curData.Id]" border
        style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="DevId" :label="$t('device.slot_no')" width="60"></el-table-column>
        <el-table-column prop="BatId" :label="$t('device.device_id')" width="130"></el-table-column>
        <el-table-column prop="slot_type" :label="$t('device.slot_type')" width="70">
          <template #default="scope">
            {{ scope.row.SlotType ? $t('device.slot_battery') :
              $t('device.slot_empty') }}
          </template>
        </el-table-column>
        <el-table-column prop="Voltage" :label="$t('device.voltage')" width="70">
          <template #default="scope">
            {{ scope.row.Voltage / 10 + 'V' }}
          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.current')" width="70">
          <template #default="scope">
            {{ scope.row.Current / 10 + 'A' }}
          </template>
        </el-table-column>
        <el-table-column prop="Soc" label="SOC" width="60"></el-table-column>
        <el-table-column prop="Soh" label="SOH" width="60"></el-table-column>
        <el-table-column prop="StartTimeStr" :label="$t('device.ebox.startChargingTime')">
        </el-table-column>
        <el-table-column prop="EndTimeStr" :label="$t('device.ebox.endChargingTime')"></el-table-column>
        <el-table-column prop="ChargeTime" :label="$t('device.ebox.chargingTime')" width="120">
          <template #default="scope">
            {{ scope.row.ChargeTime ? func.toCounterTime(scope.row.ChargeTime) : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="InitSoc" :label="$t('device.ebox.startChargingSOC')">
          <template #default="scope">
            {{ scope.row.InitSoc || '' }}
          </template>
        </el-table-column>
        <el-table-column prop="AlertInfo" :label="$t('device.ebox.faultCodes')" width="90"></el-table-column>
        <el-table-column :label="$t('device.ebox.faultChineseName')" width="110">
          <template #default="scope">
            {{ render.errorEnToCh[scope.row.AlertInfo] || '' }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('order.remark')" width="100"></el-table-column>
        <el-table-column :label="$t('device.ebox.enabledState')" width="90">
          <template #default="scope">
            <span :style="{ color: scope.row.Status == 0 ? '#E6A23C' : '#67C23A' }">
              {{ scope.row.Status ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="slot_status" :label="$t('device.ebox.switchStatus')" width="90">
          <template #default="scope">
            <span :style="{ color: scope.row.DoorStatus == 1 ? '#E6A23C' : '#67C23A' }">{{ scope.row.DoorStatus == 1 ?
              $t('g.open') : $t('g.close')
              }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="UpdateAtStr" :label="$t('g.update_at')" width="180"></el-table-column> -->
      </el-table>
    </el-dialog>
    <el-dialog title="新增工单" v-model="formData.dialogWorkOrderVisible" width="60%">

      <el-form label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="机柜Id">
              <el-input v-model="formData.curData.DevId" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机柜仓号">
              <el-input v-model="formData.curData.orderSlotId" />
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电池Id">
              <el-input v-model="formData.curData.orderBatId" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工单标题">
              <el-input v-model="formData.curData.orderTitle" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述内容">
              <el-input :rows="4" type="textarea" v-model="formData.curData.orderContent" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客服">
              <el-select class="inp" v-model="formData.curData.orderAssignUser" :placeholder="$t('g.choose')">
                <el-option v-for="item in formData.orderAssignUserList" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="售后">
              <el-select class="inp" v-model="formData.curData.orderSale" :placeholder="$t('g.choose')">
                <el-option v-for="item in formData.orderSaleList" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="12">
            <el-form-item label="优先级">
              <el-select class="inp" v-model="formData.curData.orderUrgentState" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.UrgentState" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogWorkOrderVisible = false">取消</el-button>
          <el-button type="primary" @click="func.workOrderSubmit()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="70%" align-center>
      <el-form label-width="120px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.isrent')">
            <el-select class="inp" v-model="formData.curData.IsRent" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.rentStatuses" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.isreplaceable')">
            <el-select class="inp" v-model="formData.curData.IsReturn" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.replaceStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('member.is_release')" v-if="isAdmin()">
            <el-select class="inp" v-model="formData.curData.IsRelease" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.releaseStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.deposit')">
            <el-input type="number" v-model="formData.curData.Deposit">
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('bill.settle_rate')">
            <el-input type="number" v-model="formData.curData.CountRate">
              <template #append>%</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('device.longitude')">
            <el-input type="number" v-model="formData.curData.Longitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.latitude')">
            <el-input type="number" v-model="formData.curData.Latitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.electric_price')">
            <el-input type="number" v-model="formData.curData.EnergyPrice"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.ebox.minPower')">
            <el-input type="number" placeholder="输入50至80之间" v-model="formData.curData.swapSoc">
              <template #append>%</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('device.ebox.keepEmptyNum')">
            <el-input type="number" placeholder="输入1至n(n为机柜总仓数)" v-model="formData.curData.emSlotMinNum"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.ebox.keepBatteryNum')">
            <el-input type="number" placeholder="输入1至n(n为机柜总仓数)" v-model="formData.curData.batMinNum"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt">
            <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.agentCode"
              :props="formData.props2" :options="render.options" />
          </el-form-item>
          <el-form-item :label="$t('g.operator')">
            <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.AgentPath"
              :props="formData.props" :options="render.options" />
          </el-form-item>
        </div>
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.img_dev')">
            <el-upload class="avatar-uploader" name="Img" action="/admin/device/ImgsUp" :multiple="false"
              :show-file-list="false" method="post"
              :data="{ 'id': 'ImgBox', 'DevId': formData.curData.DevId, 'ImgType': 0 }"
              :headers="{ Authorization: token }" :on-error="func.onUploadError" :on-success="func.onUploadSuccess"
              :before-upload="func.onUploadBefore" :http-request="(params) => func.onUpload(params, 0)">
              <img v-if="formData.curData.ImgBox" :src="formData.curData.ImgBox" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_site')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStore' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore"
              :http-request="(params) => func.onUpload(params, 1)">
              <img v-if="formData.curData.ImgStore" :src="formData.curData.ImgStore" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_env')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStreet' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore"
              :http-request="(params) => func.onUpload(params, 2)">
              <img v-if="formData.curData.ImgStreet" :src="formData.curData.ImgStreet" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog class="qrcode" v-model="formData.dialogQrcodeVisible" align-center>
      <div>{{ formData.qrcodeMsg }} </div>
      <canvas id="qrcode" style="width:300px;height:300px;border:1px solid black;margin-top: 20px;"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, nextTick, ref, onActivated } from 'vue'
import { shortcuts, operators, cascade_options } from '@/data/default'
import { toDay, toDay2, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox, dayjs } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator, isAdmin, isEnVersion } from '@/data/token'
import i18n from '@/data/i18n/index'
import QRCode from "qrcode" //引入生成二维码插件
import * as XLSX from 'xlsx/xlsx.mjs'
import { useRoute } from 'vue-router';
export default {
  name: 'EboxList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]
    const UrgentState = [
      {
        label: '一般',
        value: 0
      },
      {
        label: '紧急',
        value: 1
      },
      {
        label: '非常紧急',
        value: 2
      },
    ]
    const errorEnToCh = {
      'AlertSubOffline': '分控掉线',
      'AlertChargerOffline': '充电器掉线',
      'AlertBatOTH': '电池不充电等',
      'AlertBatFault': '电池故障',
      'AlertSubAerosol': '仓气溶胶故障',
      'AlertBatOffline': '电池离线',
      'AlertDoorErr': '仓门故障',
    }
    const tableRef = ref(null)
    const render = reactive({
      statuses,
      operators,
      errorEnToCh,
      UrgentState,
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      rentStatuses: [{
        label: t('device.statuses.enrent'),
        value: 1
      }, {
        label: t('device.statuses.unrent'),
        value: 0
      }],
      replaceStatuses: [{
        label: t('device.statuses.replaceable'),
        value: 1
      }, {
        label: t('device.statuses.unreplaceable'),
        value: 0
      }],
      releaseStatuses: [{
        label: t('g.no'),
        value: false
      }, {
        label: t('g.yes'),
        value: true
      }],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      shortcuts,
      options: []
    })
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      orderSaleList: [],//工单售后列表
      orderAssignUserList: [],
      valTime: '',
      valStatus: -1,
      valAlert: -1,
      dialogVisible: false,
      dialogDisplayVisible: false,
      dialogQrcodeVisible: false,
      dialogWorkOrderVisible: false,
      dialogOperateVisible: false,
      qrcodeMsg: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      UpdateAtLast: {},//电池更新最后时间 
      sortOpt: { isTempSort: 0, isSlotErrSort: 0, batExNum: 0, slotNumBan: 0, slotNumEm: 0 },
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      user_info: {},
      is_opt: false,
      isstartwith: 1
    })
    const route = useRoute();


    const func = {

      onOperate(row, column, e) {
        func.expand(row, [1])
        formData.curData = row
        formData.dialogOperateVisible = true
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.curData.AgentPath = row.CodePath
        formData.curData.agentCode = row.AgentCode
        console.log('dasdad', row)

        func.getImage()
        formData.dialogVisible = true
      },
      getSummaries(options) {
        const { columns, data } = options;

        const sums = [];
        columns.forEach((column, index) => {

          if (index === 0) {
            sums[index] = t("g.total");
            return;
            //index === 22 || index === 24 || index === 28  这是表格第几列需要合计值
            // 例如只需要第3列的值求和，index===3
          } else if (index === 11 || index === 12 || index === 13 || index === 14) {
            // let tmp = column.property.split('.');
            const values = data.map((item) => Number(item[column.property]));
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              // console.log(JSON.stringify(value),23980);
              if (!isNaN(value)) {
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                return prev;
              }
            }, 0);
          }
        });

        return sums;
      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        func.expand(row, [1])
        formData.curData = row;
        func.getImage()
        formData.dialogDisplayVisible = true;
      },
      async operateHeater(devId, type) {
        let params = {
          deviceId: devId,
          conType: type
        }
        if (!devId) {
          return
        }
        await axios.post('/admin/device/heatercontrol', params)
        ElMessage({
          type: 'success',
          message: t('device.cmd_sent'),
        })
      },
      async slotRestart(devId, SlotId) {
        try {
          let arr1 = ['1', '4', '7', '10']
          let arr2 = ['2', '5', '8', '11']
          let arr3 = ['3', '6', '9', '12']
          let hint = ''
          console.log(JSON.stringify(SlotId));

          if (arr1.includes(SlotId)) {
            hint = arr1.toString() + t('device.sure_restart_slot')
          } else if (arr2.includes(SlotId)) {
            hint = arr2.toString() + t('device.sure_restart_slot')
          } else if (arr3.includes(SlotId)) {
            hint = arr3.toString() + t('device.sure_restart_slot')
          } else {
            hint = SlotId + t('device.sure_restart_slot')
          }
          await ElMessageBox.confirm(
            hint,
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          await axios.post('/admin/device/restartDev', {
            devId,
            SlotId
          })
          ElMessage({
            type: 'success',
            message: t('device.cmd_sent'),
          })
        } catch (e) {

        }
      },
      async openSlot(devId, slotId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_open_slot'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          // console.log(JSON.stringify(slotId));
          // console.log(JSON.stringify(devId));
          // return; 
          await axios.post('/admin/device/openSlot', {
            devId,
            slotId
          })
          ElMessage({
            type: 'success',
            message: t('device.cmd_sent'),
          })
        } catch (e) {

        }
      },
      async exportAllExcel() {
        let params = {
          getAll: 1
        }
        if (formData.valStatus != -1) {
          params.status = formData.valStatus
        }
        if (formData.valAlert != -1) {
          params.alert = formData.valAlert
        }
        if (formData.valName) {
          params.name = formData.valName
        }
        if (formData.valSiteName) {
          params.siteName = formData.valSiteName
        }
        if (formData.valCode) {
          params.code = formData.valCode
        }
        if (formData.valBattaryId) {
          params.batId = formData.valBattaryId
        }
        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        if (formData.valDevName) {
          params.devName = formData.valDevName
        }
        params.isstartwith = formData.isstartwith
        //let loading = ''
        // if (params.exportType == 1) {
        //   loading = ElLoading.service({
        //     lock: true,
        //     text: t('openSlotsLit.loading'),
        //     background: 'rgba(0, 0, 0, 0.7)',
        //   })
        // }
        let res = await axios.get(`/admin/box`, {
          params
        });
        let excleData = [[]];
        let wscols = [];
        excleData = [[
          '设备名称',
          '设备编号',
          '站点名称',
          '设备地址',
          '运营商归属',
          '总仓数',
          '故障仓',
          '空仓数量',
          '禁用仓',
          '电池仓数',
          '在线状态',
          '状态',
          '创建时间',
          '更新时间',
        ]];
        wscols = [    // 每列不同宽度px
          { wch: 11 },
          { wch: 26 },
          { wch: 26 },
          { wch: 30 },
          { wch: 26 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 28 },
          { wch: 28 }
        ];
        if (res && res.List) {
          for (let item of res.List) {
            let arr = [];
            arr = [
              item.Name,
              item.DevId,
              item.SiteName,
              item.Address,
              item.NamePath,
              item.SlotNum,
              item.SlotNumErr,//故障
              item.SlotNumEm,//空仓数 
              item.SlotNumBan,//禁用仓
              item.SlotNumBattary = (item.IsOnline == 1 || item.SlotNumEm > 0) ? item.SlotNum - item.SlotNumEm : 0,//电池仓  
              item.IsOnline = item.IsOnline == 0 ? '否' : '是',
              item.Status = item.Status == 1 ? '已启用' : '已禁用',
              toDay(item.CreateAt),
              toDay(item.UpdateAt),
            ]
            excleData.push(arr)
          }
        }
        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, '换电柜导出' + '.xlsx');
      },
      addworkOrder(slot) {
        if (slot) {
          formData.curData.orderSlotId = slot.DevId
          formData.curData.orderRemark = slot.Remark
          formData.curData.orderAlertInfo = slot.AlertInfo
          formData.curData.orderBatId = slot.BatId
          formData.curData.orderUrgentState = 0
          formData.curData.orderForce = 0
          let c = slot.AlertInfo ? '故障：' + render.errorEnToCh[slot.AlertInfo] + '(' + slot.AlertInfo + ')' : ''
          formData.curData.orderTitle = c
          c += slot.Remark ? '。备注：' + slot.Remark + '' : ''
          formData.curData.orderContent = c
        }
        if (formData.orderSaleList.length == 0) {
          this.getSales()
        }
        if (formData.orderAssignUserList.length == 0) {
          this.getAssignedUserList()
        }
        formData.dialogWorkOrderVisible = true
      },
      async getAssignedUserList() {
        let params = {}
        params.IsWorkOrder = 1
        params.getAll = 1
        params.agentcode = formData.curData.AgentCode
        let res = await axios.get(`/admin/user`, {
          params
        })
        if (res && res.List) {
          formData.orderAssignUserList = []
          res.List.forEach((item) => {
            formData.orderAssignUserList.push({
              value: item.Id,
              label: item.IdName || item.Nickname
            })
          })
        }
      },
      async getSales() {
        let params = {}
        params.userStatus = 8
        params.getAll = 1
        params.agentcode = formData.curData.AgentCode
        let res = await axios.get(`/admin/user`, {
          params
        })
        if (res && res.List) {
          formData.orderSaleList = []
          res.List.forEach((item) => {
            formData.orderSaleList.push({
              value: item.Id,
              label: item.IdName || item.Nickname
            })
          })
        }
      },
      async workOrderSubmit() {
        try {
          let params = {}
          if (!formData.curData.orderAssignUser) {
            return ElMessage({
              type: 'error',
              message: '请输入客服。',
            })

          }
          params.DevId = formData.curData.DevId
          params.SlotId = formData.curData.orderSlotId
          params.BatId = formData.curData.orderBatId
          params.Title = formData.curData.orderTitle
          params.OrderDesc = formData.curData.orderContent
          params.UrgentState = formData.curData.orderUrgentState
          params.AssignedUserID = formData.curData.orderSale
          params.AssignUserID = formData.curData.orderAssignUser
          params.AgentCode = formData.curData.AgentCode
          params.CodePath = formData.curData.CodePath
          params.IsForce = formData.curData.orderForce
          let res = await axios.post('/admin/workorder/add',
            params
          )
          console.log(JSON.stringify(res), 28031);
          if (res) {
            formData.dialogWorkOrderVisible = false
            return ElMessage({
              type: 'success',
              message: t('g.success')
            })
          }
        } catch (e) {
          console.log(JSON.stringify(e), 2803);

          if (e == '改设备仓号已有工单，请确认' && formData.curData.orderForce == 0) {
            formData.curData.orderForce = 1
            await ElMessageBox.confirm('已有相同机柜和仓的未处理工单，确认是否需要重复新建？', {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
            }).then(async () => {
              func.workOrderSubmit()
            }

            )
          }

        }

      },
      async restartDev(devId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_restart_dev'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          let res = await axios.post('/admin/device/restartDev', {
            devId
          })
          console.log(JSON.stringify(res));

          if (res.indexOf('成功')) {
            ElMessage({
              type: 'success',
              message: res,
            })
          } else {
            ElMessage({
              type: 'error',
              message: res,
            })
          }

        } catch (e) {

        }
      },
      toCounterTime,
      async toggleSlotStatus(item) {
        if (item.Status === 0) {
          let res = await axios.post('/admin/device/disableSlot', {
            slotId: item.Id,
            disableInt: item.Status === 0 ? 0 : 1,
          })
          item.Status = 1 - item.Status
          ElMessage({
            type: 'success',
            message: t('g.success')
          })
        } else {

          // ElMessageBox.confirm("确认要" + (item.Status === 0 ? '启用' : '禁用') + "设备吗？", {
          //   confirmButtonText: t('confirm'),
          //   cancelButtonText: t('cancel'),
          // })
          ElMessageBox.prompt('请输入禁用原因', "确认要" + (item.Status === 0 ? '启用' : '禁用') + "设备吗？", {
            confirmButtonText: t('confirm'),
            cancelButtonText: t('cancel'),
          }).then(
            async ({ value }) => {
              let res = await axios.post('/admin/device/disableSlot', {
                slotId: item.Id,
                disableInt: item.Status === 0 ? 0 : 1,
                Remark: value
              })
              item.Status = 1 - item.Status
              ElMessage({
                type: 'success',
                message: t('g.success')
              })
            }
          )
        }


      },
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/box/slot?eboxId=" + row.Id)
          formData.slots[row.Id] = res
          let max = 0
          res.map(item => {
            item.UpdateAtStr = toDay(item.UpdateAt)
            item.StartTimeStr = item.StartTime ? dayjs.unix(item.StartTime).format('YYYY-MM-DD HH:mm') : ''
            item.EndTimeStr = item.EndTime ? dayjs.unix(item.EndTime).format('YYYY-MM-DD HH:mm') : ''
            // if (item.UpdateAt > max) {
            //   max = item.UpdateAt
            //   item.UpdateAtStr = toDay(item.UpdateAt)
            //   formData.UpdateAtLast = item;

            // }
          })
          // console.log(JSON.stringify(formData.UpdateAtLast));

        }
      },
      async sortByColumn(prop = 'BatExNum', order = 'descending') {//ascending升序  descending降序
        await nextTick()
        tableRef.value.sort(prop, order);
      },
      sort(val) {
        // console.log(val,'排序顺序值11111')
        let obj = { null: 0, descending: -1, ascending: 1 }
        formData.sortOpt.isSlotErrSort = 0
        formData.sortOpt.batExNum = 0
        formData.sortOpt.slotNumBan = 0
        formData.sortOpt.slotNumEm = 0
        // formData.sortOpt.measEnergy = 0
        formData.sortOpt.isTempSort = 0
        if (val.prop == 'Temp') {
          formData.sortOpt.isTempSort = obj[val.order]
        } else if (val.prop == 'SlotNumErr') {
          formData.sortOpt.isSlotErrSort = obj[val.order]
        } else if (val.prop == 'BatExNum') {
          formData.sortOpt.batExNum = obj[val.order]
        } else if (val.prop == 'SlotNumBan') {
          formData.sortOpt.slotNumBan = obj[val.order]
        } else if (val.prop == 'SlotNumEm') {
          formData.sortOpt.slotNumEm = obj[val.order]
        }
        console.log(val.prop, '传的值')
        func.loadData()
      },
      async getImage() {
        let res = await axios.post('/admin/device/imgsGet', {
          DevId: formData.curData.DevId
        })
        if (res) {
          if (res.devImg) {
            formData.curData.ImgBox = 'data:image/bmp;base64,' + res.devImg;
          }
          if (res.stationImg) {
            formData.curData.ImgStore = 'data:image/bmp;base64,' + res.stationImg;
          }
          if (res.streetImg) {
            formData.curData.ImgStreet = 'data:image/bmp;base64,' + res.streetImg;
          }
        }
      },
      async onUpload(data, type = 0) {
        let fd = new FormData();
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        fd.append('Img', data.file)
        fd.append('DevId', formData.curData.DevId)
        fd.append('ImgType', type)
        console.log(fd.get('Img'));
        let res = await axios.post('/admin/device/imgsUp', fd, { headers })
        func.getImage()
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      qrcode(row) {
        formData.dialogQrcodeVisible = true;

        console.log(JSON.stringify(row));
        let QRCodeMsg = 'http://' + row.AgentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + row.DevId;
        // return
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 5,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: QRCodeMsg,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };

        formData.qrcodeMsg = QRCodeMsg
        nextTick(() => {
          let msg = document.getElementById("qrcode");
          QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
            console.log(error)
          });
        })



      },
      onUploadSuccess(res) {
        console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      opt_agent(val1, val2) {
        console.log(val1, val2)
      },

      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        if (formData.curData.swapSoc < 50 || formData.curData.swapSoc > 80) {
          ElMessage({
            type: 'error',
            message: '可换电量需要50到80之间。'
          })
          return
        }
        if (formData.curData.emSlotMinNum < 1 || formData.curData.batMinNum < 1) {
          ElMessage({
            type: 'error',
            message: '保留电池仓和空仓不能小于1'
          })
          return
        }
        try {
          const params = {
            id: formData.curData.Id,
            // imgBox: formData.curData.ImgBox,
            // imgStore: formData.curData.ImgStore,
            // imgStreet: formData.curData.ImgStreet,
            status: formData.curData.Status,
            deposit: formData.curData.Deposit,
            countRate: formData.curData.CountRate,
            longitude: formData.curData.Longitude,
            latitude: formData.curData.Latitude,
            isrent: formData.curData.IsRent,
            isreturn: formData.curData.IsReturn,
            energyPrice: formData.curData.EnergyPrice,
            agentPath: formData.curData.AgentPath,
            agentCode: formData.curData.agentCode,
          }
          if (formData.curData.password) {
            params.password = formData.curData.password
          }

          if (formData.curData.swapSoc) {
            params.swapSoc = formData.curData.swapSoc
          }
          if (formData.curData.emSlotMinNum >= 1) {
            params.emSlotMinNum = formData.curData.emSlotMinNum
          }
          if (formData.curData.batMinNum >= 1) {
            params.batMinNum = formData.curData.batMinNum
          }
          if (formData.curData.IsRelease) {
            params.isrelease = formData.curData.IsRelease
          }
          await axios.put('/admin/box', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async get_options() {
        render.options = await cascade_options()
        console.log(render.options, 'dad121212')
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
            type: 1,
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          if (formData.valAlert != -1) {
            params.alert = formData.valAlert
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          if (formData.valCode) {
            params.code = formData.valCode
          }
          if (formData.valBattaryId) {
            params.batId = formData.valBattaryId
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valDevName) {
            params.devName = formData.valDevName
          }
          params.isstartwith = formData.isstartwith
          params.isTempSort = formData.sortOpt.isTempSort
          params.isSlotErrSort = formData.sortOpt.isSlotErrSort
          params.batExNum = formData.sortOpt.batExNum
          params.slotNumBan = formData.sortOpt.slotNumBan
          params.slotNumEm = formData.sortOpt.slotNumEm
          let res = await axios.get(`/admin/box`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)
            item.SlotNumBattary = (item.IsOnline == 1 || item.SlotNumEm > 0) ? item.SlotNum - item.SlotNumEm : 0
            // if (item.DevType != 'null') {
            //   item.specsStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).Specs
            //   item.ModeNoStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).ModeNo
            // }
            // if (item.OperConf) {
            //   item.hasHeater = JSON.parse(item.OperConf) && JSON.parse(item.OperConf).IsHaveHeater
            // }
            if (item.EboxConfig && item.EboxConfig != 'null') {
              item.swapSoc = JSON.parse(item.EboxConfig.OperConf).ExChgSocThreshold
              item.emSlotMinNum = JSON.parse(item.EboxConfig.OperConf).EmSlotMinNum
              item.batMinNum = JSON.parse(item.EboxConfig.OperConf).BatMinNum
            }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {

      func.loadData()
      func.get_options()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    onActivated(() => {
      if (history.state.params) {
        // console.log(history.state, '路由的值')
        if (history.state.params.type == 1) {
          formData[history.state.params.value.name] = history.state.params.value.val
          setTimeout(() => {
            func.loadData()
          }, 300);
        } else {
          func.sortByColumn(history.state.params.value.name, history.state.params.value.val)
        }
      }
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
      isAdmin,
      isEnVersion,
      tableRef,
      toDay2
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 9rem;

          &:first-child {
            width: 6rem;
          }
        }

        .deviceid {
          width: 11rem;

        }
      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  // >.data-table {
  //   :deep(.el-table) {
  //     .cell {
  //       white-space: nowrap;
  //       text-overflow: ellipsis;
  //     }

  //     .el-table__row {
  //       cursor: pointer;
  //     }
  //   }
  // } 

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep .el-form-item {
        width: 33%;
        // min-width: 30%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
    margin-top: 0;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
