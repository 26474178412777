<template>
  <div class="component">
    <div class="chart-box">
      <div :id="formData.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
  import i18n from '@/data/i18n/index'
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
export default {
  name: 'OrderToday',
  props:{
    agentCode:[String,Number],
    id:{
      type:String,
      default:"today"
    }
  },
  setup (props) {
    const t = i18n.global.t
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    const id = "chartid-order-" + props.id
    const formData = reactive({
      id
    })
    const initChart = () => {
      const chartDom = document.getElementById(id)
      myChart = echarts.init(chartDom)
      loadData()
    }
    const loadData = async () => {
      const params = {
        CodePath:props.agentCode || "",
      }
      let res = await axios.get("/admin/analyze/order/today",{
        params
      })
      const legend = [t('chart.yesterday'), t('chart.today')]
      const xAxis = []
      const data = [[], []]
      for (let i = 0; i < res.cur.length; i++) {
        xAxis.push(res.cur[i].Key)
        data[1].push(res.cur[i].Val)
        data[0].push(res.last[i].Val)
      }
      const option = {
        title: {
          text: t('chart.trends.today')
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend,
          textStyle:{
            fontSize:16,
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: legend[0],
            type: 'line',
            markPoint: {
              data: [
                { type: 'max', name: t('chart.max_value') }
              ]
            },
            data: data[0]
          },
          {
            name: legend[1],
            type: 'line',
            markPoint: {
              data: [
                { type: 'max', name: t('chart.max_value') }
              ]
            },
            data: data[1]
          }
        ]
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
