<template>
    <el-tabs v-model="activeTab" type="card" closable class="tabs" @tab-remove="debouncedClick"
        @tab-click="handleTabClick">
        <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
        </el-tab-pane>
    </el-tabs>
</template>
<script setup>
import { ref, watch,onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
let tabIndex = 2
const activeTab = ref('')
const editableTabs = ref([])

const route = useRoute()
const router = useRouter()

//sessionStorage.removeItem('table_menu');
//const table_menu = JSON.parse(sessionStorage.getItem('table_menu'))||[];

// 选中标签页的事件 (路由跳转到选中的标签页)
const handleTabClick = (tab) => {
    router.push(tab.props.name)
}

// 删除标签页
const handleTabRemove = (targetName) => {
    //filter循环找到未删除的标签页重新赋值给可选标签页
    editableTabs.value = editableTabs.value.filter(
        (tab) => tab.name !== targetName
    )
    if (activeTab.value === targetName) {
        // 获取最后一个标签并跳转到最后一个标签页并给展示的标签页赋值
        const lastTab = editableTabs.value[editableTabs.value.length - 1]
        activeTab.value = lastTab ? lastTab.name : ''
        sessionStorage.setItem('table_menu', JSON.stringify(editableTabs.value));
        router.push(activeTab.value)
    } else {
        sessionStorage.setItem('table_menu', JSON.stringify(editableTabs.value));
    }
}
//防抖
function debounce(fn, wait) {
    let timeout = null;
    return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        let callNow = !timeout;
        timeout = setTimeout(() => {
            timeout = null;
        }, wait);
        if (callNow) fn.apply(context, args);
    };
}

let debouncedClick = debounce(handleTabRemove, 200);

watch(() => route, (newVal) => {
    if (editableTabs.value.length > 10) {
        const indexToRemove = activeTab.value !== newVal.path ? 1 : 0
        editableTabs.value.splice(indexToRemove, 1)
        sessionStorage.setItem('table_menu', JSON.stringify(editableTabs.value));
    }
    editableTabs.value = JSON.parse(sessionStorage.getItem('table_menu')) || [];//table_menu.length ? table_menu : []
    const isNewTab = !editableTabs.value.some((tab) => tab.name === newVal.path)
    if (isNewTab) {
        editableTabs.value.push({
            title: newVal.meta.title,
            name: newVal.path,
        })
        sessionStorage.setItem('table_menu', JSON.stringify(editableTabs.value));
    }
    activeTab.value = newVal.path
}, { deep: true, })
//    immediate: true 

onMounted(()=>{
    let arr = JSON.parse(sessionStorage.getItem('table_menu')) || [];
    if(arr.length!=0){
        arr.forEach(element => {
            let match = router.options.routes.find(Obj => Obj?.path == element.name);
            if(match){
                element.title = match.meta.title
            }
        });
    }
    sessionStorage.setItem('table_menu', JSON.stringify(arr));
})
</script>
<style>
.el-tabs--card>.el-tabs__header {
    background-color: var(--tabs-bg-color) !important;
}

.el-tabs__item.is-active {
    background-color: #409eff;
    color: #fff !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable {
    padding-left: 13px;
    padding-right: 13px;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable .is-icon-close {
    width: 14px;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 13px !important;
}

.el-tabs__item.is-active {
    background-color: #409eff;
    color: #fff !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable {
    padding-left: 13px;
    padding-right: 13px;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-closable .is-icon-close {
    width: 14px;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 13px !important;
}

.el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
    padding-right: 13px !important;
}

.el-tabs__header {
    margin: 0px !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none !important;
    border-left: 1px solid #e4e7ed !important;
    border-right: 1px solid #e4e7ed !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #409eff !important;
}

.el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:last-child,
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:last-child {
    padding-right: 13px !important;
}

.el-tabs__header {
    margin: 0px !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none !important;
    border-left: 1px solid #e4e7ed !important;
    border-right: 1px solid #e4e7ed !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #409eff;
}

.el-tabs--card>.el-tabs__header {
    border-top: 1px solid #e4e7ed;
}
</style>