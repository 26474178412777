<template>
    <div class="content">
      <div class="card-box">
        <div class="header">
          <span class="title">{{ $t("g.condition") }}</span>
        </div>
        <div class="inp-list">
          <div class="item">
            <span class="label">{{ $t('g.operator') }}</span>
            <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
              :props="formData.props" :options="render.cascade_options_direct" />
          </div>
          <div class="item">
            <span class="label">发券方式</span>
            <el-select class="inp" size="small" v-model="formData.valmethod" :placeholder="$t('g.choose')" clearable>
              <el-option v-for="item in render.method" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="btn-ct">
          <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
        </div>
      </div>
      <div class="card-box data-table">
        <div class="header">
          <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
            }}</span>
          <div style="float:right">
            <el-button type="primary" @click="func.newActivity">{{ $t('device.add') }}</el-button>
          </div>
          <div class="header-icons">
          </div>
  
        </div>
        <el-table :data="formData.tableData" :stripe="true" @row-click="func.showDetails" style="width: 100%"
          class="el-table">
          <el-table-column prop="Id" label="#" width="50px" fixed>
          </el-table-column>
          <el-table-column prop="Name" :label="$t('coupon.name')" width="150px" fixed>
          </el-table-column>
          <el-table-column prop="Type" :label="$t('coupon.type')" width="130px">
            <template #default="scope">
              {{ render.type[scope.row.Type].label }}
            </template>
          </el-table-column>
          <el-table-column prop="Value" :label="$t('coupon.face_value')">
            <template #default="scope">
              {{ scope.row.Value }}{{ scope.row.Type == 0 ? '元' : '天' }}
            </template>
          </el-table-column>
          <el-table-column prop="DistributeMethod" :label="$t('coupon.coupon_issuance_method')" width="130px">
            <template #default="scope">
              {{ render.method[scope.row.DistributeMethod].label }}
            </template>
          </el-table-column>
          <el-table-column prop="DistributeNum" :label="$t('coupon.circulation')">
          </el-table-column>
          <el-table-column prop="DistributedNum" :label="$t('coupon.actual_circulation')" width="100px">
          </el-table-column>
          <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column prop="AgentCode" :label="$t('g.agent_code')" width="100px">
          </el-table-column>
          <el-table-column prop="Enable" :label="$t('coupon.status')">
            <template #default="scope">
              <el-tag :type="scope.row.Enable ? 'success' : 'warning'">{{ render.statuses[scope.row.Enable].label
                }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="startAtStr" :label="$t('coupon.start_time')" width="120px">
          </el-table-column>
          <el-table-column prop="endAtStr" :label="$t('coupon.end_time')" width="120px">
            <template #default="scope">
              <span :style="{ color: scope.row.endAtExpired ? '#606266' : '#c8cace' }">{{ scope.row.endAtStr }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="CreateAtStr" :label="$t('g.create_at')" width="180px">
          </el-table-column>
          <el-table-column prop="Remark" :label="$t('coupon.remark')" width="160px">
          </el-table-column>
          <!-- <el-table-column prop="Name" :label="$t('coupon.rule')">
          </el-table-column> -->
  
          <el-table-column :width="150" :label="$t('g.operation')" fixed="right">
            <template #default="scope">
              <el-tag size="large" type="success" @click="func.editRule(scope.row)">{{ $t('g.edit') }}</el-tag>
              <el-tag size="large" type="warning" style="margin-left: 0.5rem;"
                @click="func.deleteRule(scope.row.Id, scope.row.EndTime)">{{ $t('g.delete') }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
  
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
      <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible">
        <el-descriptions border>
          <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.name')">{{ formData.curData.Name }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.type')">
            {{ render.type[formData.curData.Type].label }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.face_value')"> {{ formData.curData.Value }}{{ formData.curData.Type ==
            0 ?
            '元' : '天' }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.coupon_issuance_method')">
            {{ render.method[formData.curData.DistributeMethod].label }}
          </el-descriptions-item>
          <el-descriptions-item label="发劵套餐" v-if="formData.curData.DistributeMethod == 3">{{
            formData.curData.ComboInfo?.Name
          }}</el-descriptions-item>
          <el-descriptions-item label="套餐价格" v-if="formData.curData.DistributeMethod == 3">{{
            formData.curData.ComboInfo?.Price
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.circulation')">{{ formData.curData.DistributeNum
            }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.actual_circulation')">{{ formData.curData.DistributedNum
            }}</el-descriptions-item>
          <el-descriptions-item :label="$t('g.agent_code')">
            {{ formData.curData.AgentInfo.LevelName || formData.curData.AgentInfo.Code }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.status')">
            <el-tag :type="formData.curData.Enable ? 'success' : 'warning'">{{
              render.statuses[formData.curData.Enable].label
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.start_time')">{{ formData.curData.startAtStr }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.end_time')">{{ formData.curData.endAtStr }}</el-descriptions-item>
          <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
          <el-descriptions-item :label="$t('coupon.remark')">{{ formData.curData.Remark }}</el-descriptions-item>
        </el-descriptions>
      </el-dialog>
      <el-dialog :title="formData.operateType == 1 ? $t('device.add') : $t('g.edit')" v-model="formData.dialogVisible"
        class="new-el-dialog" center width="60%" >
        <div class="dailog-content">
          <div class="activity-content">
            <el-form label-width="120px" class="e-form" :rules="rules" :model="formData" ref="formDom">
              <el-form-item :label="$t('coupon.name')"  prop="name" style="width: 350px;">
                <el-input v-model="formData.name" />
              </el-form-item>
              <el-form-item :label="$t('coupon.type')" style="width: 350px;">
                <el-select   v-model="formData.type" :placeholder="$t('g.choose')"  style="width: 100%;flex: 1;">
                  <el-option v-for="item in render.type" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('coupon.face_value')" style="width: 350px;" prop="face_value">
                <el-input v-model="formData.face_value" >
                  <template #append>{{ formData.type == 0 ? '元' : '小时' }}</template>
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('receive_list.receive_channel')" style="width: 350px;" >
                <el-cascader style="width: 100%;flex: 1;"  :clearable="true" v-model="formData.operator"
                  :props="formData.props" :options="render.cascade_options_direct" />
                <!-- <el-select class="inp" size="small" v-model="formData.operator" :placeholder="$t('g.choose')">
                  <el-option v-for="item in render.cascade_options_direct" >
                  </el-option>
                </el-select> -->
                <!-- <el-cascader v-model="formData.operator" :options="render.operators" :props="{ checkStrictly: true }"
                  style="width:500px" /> -->
              </el-form-item>
              <el-form-item label="运营商" style="width: 350px;" >
                <el-cascader  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.CodePath"
                  :props="formData.props2" :options="render.cascade_options_direct" />
              </el-form-item>
              <el-form-item :label="$t('coupon.coupon_issuance_method')" style="width: 350px;">
                <el-select style="width: 100%;flex: 1;" v-model="formData.method" :placeholder="$t('g.choose')">
                  <el-option v-for="item in render.method" :key="item.value" :label="item.label" :value="item.value"
                    :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="自动发劵套餐" style="width: 350px;" v-if="formData.method == 3">
                <el-select   v-model="formData.comboId" :placeholder="$t('g.choose')">
                  <el-option v-for="item in formData.comboList" :key="item.Id" :label="item.Name" :value="item.Id"
                    :disabled="item.disabled">
                    {{ item.Name }} {{ item.Price }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('coupon.circulation')" style="width: 350px;" prop="circulation">
                <el-input v-model="formData.circulation" />
              </el-form-item>

              <el-form-item :label="$t('coupon.status')" style="width: 350px;">
                <el-select style="width: 100%;flex: 1;"  v-model="formData.status" :placeholder="$t('g.choose')">
                  <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('coupon.time')" style="width: 350px;" prop="valTime">
                <el-date-picker  v-model="formData.valTime" value-format="YYYY-MM-DD" 
                  type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
                  :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('coupon.remark')" style="width: 350px;">
                <el-input v-model="formData.name" />
              </el-form-item>
            </el-form>
  
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="formData.dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="func.updateData()">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  <script>
  import { reactive, onMounted, computed, ref, watch,nextTick  } from 'vue'
  import activityStyle from '@/components/activityMode/activityStyle.vue'
  import i18n from '@/data/i18n/index'
  import { toDay, toDay3, toCounterTime } from '@/common/func'
  import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
  import axios from "axios"
  import { user, isOperator, isAdmin } from '@/data/token'
  import { shortcuts, operators, operatorCascader, pile, combo, cascade_options_direct } from '@/data/default'
  export default {
    name: 'restrictionRule',
    components: {
      activityStyle
    },
    setup() {
      const t = i18n.global.t
      const render = {
        combo,
        pile,
        operators,
        operatorCascader,
        cascade_options_direct,
        shortcuts,
        statuses: [
          {
            label: t('device.disable'),
            value: 0
          },
          {
            label: t('device.enable'),
            value: 1
          }
  
        ],
        type: [
          {
            label: t('coupon.render.type.cash'),
            value: 0
          },
          // {
          //   label: t('coupon.render.type.time'),
          //   value: 1
          // },
          {
            label: '租期时长优惠券',
            value: 1
          }
        ],
        method: [
          {
            label: t('coupon.render.method.new_user_register'),
            value: 0,
            disabled: false,
          },
          {
            label: t('coupon.render.method.develop_new_user'),
            value: 1,
            disabled: false,
          },
          {
            label: t('coupon.render.method.target'),
            value: 2,
            disabled: false,
          },
          {
            label: t('coupon.render.method.combo_aotu'),
            value: 3,
            disabled: false,
          },
          // {
          //   label: t('coupon.render.method.h5'),
          //   value: 4,
          //   disabled: true,
          // },
        ]
      }
      const formDom = ref(null)
      const formData = reactive({
        tableData: [],
        title: '',
        operateType: 1,//操作类型 1 新增 2 编辑
        id: 0,
        name: '',
        type: 0,
        face_value: '',
        method: 0,
        circulation: '',
        comboList: [],
        valTime: '',
        remark: '',
        status: 0,
        dialogVisible: false,
        dialogDisplayVisible: false,
        total: 1,
        curPage: 1,
        pageSize: 10,
        props: {
          children: "Children",expandTrigger: 'hover',
          label: "Name",
          value: "Code",
          emitPath: false,
          checkStrictly: true
        },
        props2:{
            children:"Children",expandTrigger: 'hover',
            label:"Name",
            value:"CodePath",
            emitPath:false,
            checkStrictly:true
        },
      })
      const rules = reactive({
        name: [{ required: true, message: '请输入优惠券名称', trigger: 'change' }],
        face_value: [{ required: true, message: '请输入面额', trigger: 'change' }],
        circulation: [{ required: true, message: '请输入发行量', trigger: 'change' }],
        valTime: [{ required: true, message: '请输入起止时间', trigger: 'change' }],
      })
      watch(() => formData.method, (newVal, oldVal) => {
        console.log(newVal);
        if (newVal == 3) {
          func.getCombo()
        }
      })
      const func = {
        showDetails(row, column) {
          if (column && column.label == t('g.operation')) {
            return;
          }
          formData.dialogDisplayVisible = true;
          formData.curData = row;
        },
        search() {
          func.loadData()
        },
        pageChange(val) {
          func.loadData(val)
        },
        newActivity() {
          formData.operateType = 1;
          formData.id = ''
          formData.name = ''
          formData.type = 1
          formData.face_value = ''
          formData.method = 0
          formData.circulation = ''
          formData.valTime = ''
          formData.status = 1
          formData.remark = ''
          formData.comboId = ''
          formData.sendTimes = ''
          formData.operator = ''
          formData.CodePath = ''
          formData.dialogVisible = true;
          //等待 DOM 更新完成 否则报错
          nextTick(()=>{
            formDom.value.clearValidate()
          })
          
        },
        async updateData() {
          formDom.value.validate(async (valid) => {
            if (valid) {
              if (formData.operateType == 1) {
                try {
                  let operator = ''
  
                  const params = {
                    name: formData.name,
                    type: formData.type,
                    value: formData.face_value,
                    method: formData.method,
                    num: formData.circulation,
                    remark: formData.remark,
                    enable: formData.status,
                    comboId: formData.comboId,
                    comboxTimes: formData.sendTimes,
                    // operator:operator
                  }
                  if (formData.operator) {
                    // params.agentId = formData.operator[formData.operator.length - 1]
                    // params.agentId = formData.operator
                    params.agentCode = formData.operator
                  }
                  if (formData.CodePath) {
                    params.CodePath = formData.CodePath
                  }
                  if (formData.valTime) {
                    params.startTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
                    params.endTime = Math.floor(new Date(formData.valTime[1] + " 23:59:59").getTime() / 1000)
                  }
                  let res = await axios.post('/admin/coupon/add', params)
                  if (res == '成功') {
                    ElMessage({
                      type: 'success',
                      message: t('g.success')
                    })
                  } else {
                    ElMessage({
                      type: 'error',
                      message: t('err_code.20017')
                    })
                  }
                } catch (e) {
                }
              } else if (formData.operateType == 2) {
                try {
                  const params = {
                    id: formData.id,
                    name: formData.name,
                    type: formData.type,
                    value: formData.face_value,
                    method: formData.method,
                    num: formData.circulation,
                    remark: formData.remark,
                    enable: formData.status,
                    comboId: formData.comboId,
                    comboxTimes: formData.sendTimes,
                  }
                  if (formData.operator) {
                    // params.agentId = formData.operator[formData.operator.length - 1]
                    params.agentCode = formData.operator
                  }
                  if (formData.CodePath) {
                    params.CodePath = formData.CodePath
                  }
                  // console.log(JSON.stringify(formData.operator));
                  // console.log(JSON.stringify(params.agentId));
                  if (formData.valTime) {
                    params.startTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
                    params.endTime = Math.floor(new Date(formData.valTime[1] + " 23:59:59").getTime() / 1000)
                  }
  
                  let res = await axios.post('/admin/coupon/edit', params)
                  if (res == '操作成功') {
                    ElMessage({
                      type: 'success',
                      message: t('g.success')
                    })
                  } else {
                    ElMessage({
                      type: 'error',
                      message: t('err_code.20017')
                    })
                  }
                } catch (e) {
                }
              }
  
              formData.dialogVisible = false
              func.loadData(formData.curPage)
  
            } else {
  
              return ElMessage({
                type: 'error',
                message: t('err_code.20017')
              })
            }
          })
  
  
  
        },
        async editRule(row) {
          formData.operateType = 2;
          try {
            // console.log(JSON.stringify(row));
  
            let params = {}
            if (row) {
              formData.id = row.Id
              formData.name = row.Name
              formData.type = row.Type
              formData.face_value = row.Value
              formData.method = row.DistributeMethod
              formData.circulation = row.DistributeNum
              formData.valTime = [row.startAtStr, row.endAtStr]
              formData.remark = row.Remark;
              formData.status = row.Enable
              formData.dialogVisible = true;
              //formData.operator = row.AgentId;
              formData.operator = row.AgentCode;
              formData.CodePath = row.CodePath; 
              formData.comboId = row.ComboId
              console.log(row.AgentCode, 1234);
  
              // if (row.AgentInfo && row.AgentInfo.LevelName) {
              //   let arrFortmp = [];
              //   let arrtmp = row.AgentInfo.LevelName.split('/')
              //   let stmp = ''
              //   for (let i = 0; i < arrtmp.length - 1; i++) {
              //     stmp = arrtmp.slice(0, i + 1).join('/')
              //     let itemtmp = operators.find((element) => {
              //       return element.levelname == stmp
              //     })
              //     if (itemtmp) {
              //       arrFortmp.push(itemtmp.id)
              //     }
              //   }
              //   arrFortmp.push(row.AgentInfo.Id)
              //   formData.operator = arrFortmp //[23,27] 
              // } else {
              //   formData.operator = []
              // }
            }
  
          } catch (error) {
  
          }
        },
        async getCombo() {
          let params = {}
          console.log(JSON.stringify(formData.operator), 23);
  
          // if (formData.operator) {
          //   params.agentCode = operators.find((i) => i.id == formData.operator).value
          //   // params.agentCode = formData.operator[formData.operator.length - 1]
          // }
          if (formData.CodePath) {
            params.CodePath = formData.CodePath
          }
          if (formData.valOperator) {
            params.agentCode = formData.valOperator
          }
          console.log(params, 1244);
          let res = await axios.get(`/admin/combo`, {
            params
          })
          formData.comboList = res.List
        },
        async loadData(page = 1) {
          try {
            formData.tableData = []
            formData.curPage = page
            let params = {
              page,
            }
            if (formData.valTypes != -1) {
              params.openType = formData.valTypes
            }
            console.log(JSON.stringify(formData.valOperator));
  
            if (formData.valOperator) {
              params.agentCode = formData.valOperator
            }
  
            params.distributeMethod = formData.valmethod
  
            let res = await axios.get('/admin/coupon/get', {
              params
            })
            formData.total = res.Total
            res.List.map((item) => {
  
              item.CreateAtStr = toDay(item.CreatedAt)
              item.startAtStr = toDay3(item.StartTime)
              item.endAtStr = toDay3(item.EndTime)
              item.endAtExpired = dayjs().add(-1, 'day').isBefore(dayjs.unix(item.EndTime))
  
            })
            formData.tableData = res.List
            formData.pageSize = res.PageSize
  
          } catch (e) {
            console.log("loadData", e)
          }
        },
        async deleteRule(id, endAt) {
          // if(endAt>Math.floor((new Date()).valueOf()/1000)){
          //   ElMessage({
          //       type: 'error',
          //       message: t('err_code.20043')
          //   })
          //   return;
          // }
          // console.log(JSON.stringify(232), id);
          ElMessageBox.confirm(t('g.sure_delete'), {
            confirmButtonText: t('confirm'),
            cancelButtonText: t('cancel'),
          }).then(() => {
            try {
              console.log(JSON.stringify(id), 230);
  
              axios.post('/admin/coupon/delte', {
                id
              }).then((res) => {
                if (res == '操作成功') {
                  ElMessage({
                    type: 'success',
                    message: t('g.success')
                  })
                } else {
                  ElMessage({
                    type: 'error',
                    message: t("err_code.20005")
                  })
                }
                func.loadData()
              })
  
            } catch (error) {
              ElMessage({
                type: 'error',
                message: t("err_code.20005")
              })
            }
  
          })
  
  
        }
      }
  
      onMounted(() => {
        func.loadData()
      })
      return {
        func,
        formData,
        render,
        token: user.token,
        isOperator,
        isAdmin,
        rules,
        formDom
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .content {
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
  
    >.card-box {
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
  
      .header {
        text-align: left;
        margin-bottom: 1.25rem;
        position: relative;
      }
  
      .inp-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
  
        >.item {
          width: 49%;
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
  
          .label {
            margin-right: 0.625rem;
            width: 4.375rem;
          }
  
          :deep(.el-range-editor--small.el-input__inner) {
            flex: 1;
          }
  
          .inp {
            flex: 1;
          }
        }
      }
  
      >.btn-ct {
        text-align: right;
  
        :deep(.el-button--small) {
          padding: 0.625rem 2rem;
        }
      }
  
      >.pagination {
        text-align: right;
        margin-top: 1.25rem;
      }
  
    }
  
    .new-el-dialog {
      .dailog-content {
        display: flex;
        // width:500px;
      }
  
      .activity-content {
        flex: 2
      }
  
      .activity-style {
        flex: 1;
      }
  
      .e-form {
        display: flex;
        flex-wrap: wrap;
      }
  
    }
  
    :deep(.m-dialog) {
      // max-width: 80rem;
      // margin-top: 0;
    }
  }
  </style>