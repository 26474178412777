<template>
  <div class="content">

    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("log.trigger_time") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.no") }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevId" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.agentcode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('log.detailed_logs') }}</span>
          <el-select class="inp" size="small" v-model="formData.valLogRes" :placeholder="$t('g.choose')" filterable
            clearable allow-create>
            <el-option v-for="item in render.failReason" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table @row-click="func.rowDblClick" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="100">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" :label="$t('log.trigger_time')" width="180">
        </el-table-column>
        <el-table-column prop="DeviceName" width="160" :label="$t('device.name')">
        </el-table-column>
        <el-table-column prop="DevId" width="240" :label="$t('device.no')">
        </el-table-column>
        <el-table-column prop="TypeStr" width="120" :label="$t('log.type')">
        </el-table-column>
        <el-table-column prop="log.type_txt" width="160" :label="$t('log.info')">
        </el-table-column>
        <el-table-column prop="DeviceAddress" min-width="300" :label="$t('device.address')">
        </el-table-column>
        <el-table-column prop="logStr" width="350" show-overflow-tooltip :label="$t('log.info_detail')">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="$t('tips')" v-model="formData.dialogVisible" width="80%" align-center>
      <el-descriptions border>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.DeviceName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('log.type')">{{ formData.curData.TypeStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('log.trigger_time')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('log.info')">{{ formData.curData.log.type_txt }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">{{ formData.curData.DeviceAddress }}</el-descriptions-item>
        <el-descriptions-item :label="$t('log.info_detail')">{{ formData.curData.logStr }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="formData.dialogVisible = false">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { shortcuts, cascade_options_direct } from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'

export default {
  name: 'LogSystem',
  setup() {
    const t = i18n.global.t
    const tableData = [
    ]
    const failReason = [
      {
        label: t('log.cabinet_disconnection'),
        value: '柜机掉线'
      },
      {
        label: t('log.battery_disconnection'),
        value: '电池掉线'
      },
      {
        label: t('log.door_malfunction'),
        value: '分控掉线'
      },
    ]
    const render = {
      shortcuts,
      cascade_options_direct,
      failReason
    }
    const alertType = [t('log.types.t0'), t('log.types.t1'), t('log.types.t2'), t('log.types.t3'), t('log.types.t4'), t('log.types.t5')]
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valDevId: '',
      valName: '',
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valAddress: '',
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true

      },
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valDevId) {
            params.devId = formData.valDevId
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valLogRes) {
            params.logInfo = formData.valLogRes
          }
          if (formData.valAddress) {
            params.address = formData.valAddress
          }
          if (formData.agentcode) {
            params.agentcode = formData.agentcode
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/log/dev`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            // console.log(JSON.stringify(item));
            item.logStr = item.Log
            if (item.Log.indexOf('mqtt掉线') != -1) {
              item.log = { 'type_txt': item.Log };
            } else {

              item.log = (item.Log && item.Log != "null") ? JSON.parse(item.Log) : ""
            }

            item.CreatedAtStr = toDay(item.CreateAt)
            item.TypeStr = ""
            item.TypeStr = t("log.types.t" + item.Type)
          })
          // console.log(JSON.stringify(formData.tableData));

          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      axios.post('/admin/log/dev/read', {})
    })
    return {
      func,
      render,
      formData,
      alertType
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;

        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    // :deep(.el-table) {
    //   .cell {
    //     white-space: normal;
    //     text-overflow: ellipsis;
    //   }

    //   .el-table__row {
    //     cursor: pointer;
    //   }
    // }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
