<template>
  <div class="component">
    <!-- <div class="fast-btn">
      <el-button @click="func.toggleMainMenu" class="btn" size="small" type="primary" icon="More" circle></el-button>
      <el-button @click="func.toggleFastIcon" class="btn" size="small" type="primary" icon="Warning" circle></el-button>
    </div> -->
    <div class="left_btn">
      <el-icon class="icon" @click="callParentMethod">
        <Fold v-show="!type" />
        <Expand v-show="type" />
      </el-icon>
    </div>
    <div class="icon-list">
      <!-- <div style="margin-right: 20px;" v-if="!isOperator()"><a target="_blank" href="/bigScreen"
          style="text-decoration: none;color:#409eff;font-size:20px">大屏</a></div> -->
      <!-- <HeaderPopup :key="idx" v-for="(item, idx) in 2" :type="idx" class="item" /> -->
      <span class="popup-ct" @click="jump">
        <el-icon class="icon">
          <DataLine />
        </el-icon>
      </span>
      <div class="coordinate">
        <el-badge is-dot type="danger" class="popup-ct" @click.stop="feedback()">
          <el-icon class="icon">
            <ChatDotSquare />
          </el-icon>
        </el-badge>
        <div class="popup-box" v-show="show" @click.stop="show = true">
          <div class="title">{{ t('common.popup_titles.t0') }}</div>
          <div class="body">
            <div class="empty-txt" v-if="msg_data.length == 0">{{ $t('common.no_content') }}</div>
            <div class="data-list" v-else>
              <div @click.stop="func.navTo(item.url.name, item.url.params)" class="item" v-for="(item, idx) in msg_data"
                :key="idx">
                <div class="l1">
                  <div class="title">
                    <span>{{ item.title }}</span>
                    <span class="status" v-if="item.status">{{ item.status }}</span>
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
                <div class="l2">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="coordinate">
        <el-badge is-dot type="danger" class="popup-ct" @click.stop="warn()">
          <el-icon class="icon">
            <Bell />
          </el-icon>
        </el-badge>
        <div class="popup-box" v-show="show1" @click.stop="show1 = true">
          <div class="title">{{ t('common.popup_titles.t1') }}</div>
          <div class="body">
            <div class="empty-txt" v-if="log_data.length == 0">{{ $t('common.no_content') }}</div>
            <div class="data-list" v-else>
              <div @click.stop="func.navTo(item.url.name, item.url.params)" class="item" v-for="(item, idx) in log_data"
                :key="idx">
                <div class="l1">
                  <div class="title">
                    <span>{{ item.title }}</span>
                    <span class="status" v-if="item.status">{{ item.status }}</span>
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
                <div class="l2">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="locale-changer">
        <el-select size="small" v-model="curLocale">
          <el-option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale
            }}</el-option>
        </el-select>
      </div>
      <div class="user" @click="func.logOut">
        <span>{{ user.name || $t("login.unlogin") }}</span>
        <img src="@/assets/logo.png" />
      </div>
    </div>
    <div class="fast-icon-list" v-show="showFastIconList">
      <!-- <HeaderPopup :key="idx" v-for="(item,idx) in 3" :type="idx" class="item"/> -->
    </div>
  </div>
</template>

<script>
import HeaderPopup from '@/components/common/HeaderPopup.vue'
import { navTo } from '@/common/func'
import { ElMessageBox, ElMessage } from 'element-plus'
import { toggleMainMenu } from '@/data/default'
import { ref, watch, onBeforeUnmount, onMounted } from 'vue'
import { user } from '@/data/token'
import { isOperator } from '@/data/token'
import axios from 'axios'
import i18n from '@/data/i18n/index'
import storage from '@/common/storage'
import { toDay2 } from '@/common/func'

export default {
  name: 'PublicHeader',
  components: {
    HeaderPopup
  },
  props: {
    parentMethod: {
      type: Function,
    }
  },
  setup(props) {
    const curLocale = ref(storage.get('locale') || '中文')
    watch(curLocale, (newVal, oldVal) => {
      storage.set('locale', curLocale.value)
      window.location.reload()
    })
    const showFastIconList = ref(false)
    const t = i18n.global.t
    let show = ref(false)
    let show1 = ref(false)
    const func = {
      toggleMainMenu,
      toggleFastIcon() {
        showFastIconList.value = !showFastIconList.value
      }
    }
    func.navTo = navTo
    func.logOut = () => {
      ElMessageBox.confirm(t('login.confirm_logout'), t('tips'), {
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
        type: 'warning'
      })
        .then(() => {
          return axios.get("/admin/login/loginOut")
        })
        .then(() => {
          navTo('Login')
        })
        .catch(() => {
        })
    }
    function jump() {
      window.open('/bigScreen', '_blank');
    }
    let msg_data = ref([])
    async function get_msg() {
      const res = await axios.get('/admin/faq', {
        params: {
          page: 1,
          newest: 1
        }
      })
      let data = []
      for (let item of res.List) {
        data.push({
          title: item.Username,
          time: toDay2(item.CreateAt),
          status: '',
          content: item.Question,
          url: {
            name: 'AfterSellFeedBack',
            params: {}
          }
        })
      }
      msg_data.value = data
    }
    const feedback = async () => {
      await get_msg()
      show.value = !show.value
    }
    const warn = async () => {
      await get_log()
      show1.value = !show1.value
    }

    let log_data = ref([])
    async function get_log() {
      const res = await axios.get('/admin/log/dev', {
        params: {
          page: 1,
          unRead: 1
        }
      })
      let data = []
      for (let item of res.List) {
        let tmp = {};
        try {
          tmp = JSON.parse(item.Log)
        } catch {
          tmp['type_txt'] = item.Log;
        }
        data.push({
          title: item.DeviceName,
          time: toDay2(item.CreateAt),
          status: tmp.type_txt,
          content: item.DeviceAddress,
          url: {
            name: 'LogApi',
            params: {}
          }
        })
      }
      log_data.value = data
    }
    const hideCpn = (e) => {
      show.value = false
      show1.value = false
    }
    onMounted(() => {
      get_msg()
      get_log()
      // setInterval(() => {
      //   get_msg()
      //   get_log()
      // }, 60000)
      document.body.addEventListener('click', hideCpn, true)
    })
    onBeforeUnmount(() => {
      document.body.removeEventListener('click', hideCpn, true)
    })

    let type = ref(false)
    const callParentMethod = () => {
      type.value = !type.value
      props.parentMethod(type.value);
    }; type.value

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 600) {
        if (type.value != false) {
          type.value = false
          props.parentMethod(type.value);
        }
      } else {
        if (type.value != true) {
          type.value = true
          props.parentMethod(type.value);
        }
      }
    });
    return {
      curLocale,
      func,
      showFastIconList,
      user,
      isOperator,
      jump,
      show,
      show1,
      t,
      msg_data,
      log_data,
      callParentMethod,
      type,
      feedback,
      warn
    }
  }
}
</script>

<style lang="scss" scoped>
.component {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.875rem;
  justify-content: space-between;
  background-color: var(--header-bg-color);

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

    >img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.625rem;

    }
  }

  .fast-btn {
    display: none;
  }

  .left_btn {
    font-size: 1.2625rem;

    .icon {
      // color: #5a6977;
      color: #385682;
    }
  }

  .icon-list {
    display: flex;
    align-items: center;

    .item {
      margin-right: 2rem;
    }

    .locale-changer {
      margin-right: 1rem;
      width: 4.5rem;
      text-align: center;
    }

    .user {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #3f51b5;
      }

      >span {
        margin-right: 8px;
        // font-weight: bold;
      }

      >img {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
        border: 3px solid #fff;
      }
    }
  }
}

.popup-ct {
  cursor: pointer;
  margin-right: 30px;
  height: 22px;

  >i {
    font-size: 1.3625rem;
    color: #666a6e;
  }

  &:hover {
    >i {
      color: #1b8cfc;
    }
  }
}

.coordinate {
  position: relative;
}

.popup-box {
  position: absolute;
  top: 2.2rem;
  left: -9.1rem;
  width: 18.75rem;
  border-radius: 0.3125rem;
  border: 1px solid #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #f2f6f9;
  z-index: 99999;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 1.25rem;

  >.title {
    text-align: left;
  }

  >.body {
    .empty-txt {
      color: #969696;
      margin: 1.25rem 0;
    }

    .data-list {
      margin: 0.625rem 0;

      >.item {
        border-top: 1px solid #ddd;
        margin-top: 0.625rem;
        padding-top: 0.625rem;
        cursor: pointer;

        >.l1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          >.title {
            font-weight: 500;

            .status {
              color: #f44336;
              margin-left: 0.375rem;
            }
          }

          >.time {
            font-size: 0.75rem;
          }
        }

        >.l2 {
          text-align: left;
          color: #969696;
          font-size: 0.8125rem;
          margin-top: 0.375rem;
        }
      }
    }
  }

  >.more {
    color: royalblue;
    cursor: pointer;
  }
}

.icon {
  cursor: pointer;
}

@media screen and (max-width:800px) {
  .component {
    flex-wrap: wrap;

    .logo {
      display: none;
    }

    .fast-btn {
      display: inline-block;

      >.btn {
        font-weight: bold;
        font-size: 1rem;
        padding: 0.375rem;
      }
    }

    .icon-list {
      .item {
        display: none;
      }
    }

    .fast-icon-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.25rem;
    }
  }
}
</style>
