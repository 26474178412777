<template>
  <div class="login_bg" id="loginbg">
    <img class="login_bg_img" :src="require('@/assets/img/login_bg_new2.png')" />
    <a class="website-url" target="_blank" href="https://www.mdacharger.com/"></a>
    <div class="login_hg_right">
      <!-- <div class="company_logo">
            <img src="@/assets/img/MDA_LOGO.png" alt="麦迪瑞图标">
          </div> -->


      <div v-if="loginType === 'qrcode'" class="login_black_hg">
        <img class="qrcode" src="../assets/logo.png" />
        <span class="qrcode-txt">{{ $t("login.wechat_scan_to_login") }}</span>
      </div>
      <div class="login_black_hg login-right-warp" v-else>
        <span class="login-right-warp-one"></span>
        <span class="login-right-warp-two"></span>
        <div :class="!enLang ? 'head_log' : 'en_head_log'">
          {{ $t("appname") }}
          <p v-if="!enLang">Dandelion Management System</p>
        </div>
        <!-- <div class="unline" style="margin-bottom: 10px"></div> -->
        <div class="form-group">
          <el-input style="width:330px;height: 40px;" size="big" v-model="username"
            :placeholder="$t('rights.username')">
            <template #prefix>
              <el-icon>
                <User />
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="form-group">
          <el-input style="width:330px;height: 40px;" type="password" size="big" v-model="password" class="login_input"
            :placeholder="$t('rights.password')">
            <template #prefix>
              <el-icon>
                <Lock />
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="form-group verification">
          <!-- 40px -->
          <el-input style="width:210px;height: 40px;margin-right: 20px;" size="big" v-model="verif.code"
            class="login_input" :placeholder="$t('login.verification_code')">
            <template #prefix>
              <el-icon>
                <CircleCheck />
              </el-icon>
            </template>
          </el-input>
          <el-image style="width:100px;height: 40px;" :alt="$t('login.verification_code')" class="vefi-img"
            :src="verif.img" @click="verifCode"></el-image>
        </div>
        <el-button size="big" class="login_btn" @click="login" type="primary">{{ $t("login.btn_login")
          }}</el-button>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import cfg from '@/common/conf'
import { navTo } from '@/common/func'
import { useRoute } from 'vue-router'
import axios from "axios"
import { setUser } from "@/data/token"
import storage from '@/common/storage'
import { updateOperators, getCoord } from '@/data/default'
export default {
  name: 'Login',
  setup() {
    const route = useRoute()
    const username = ref('')
    const password = ref('')
    const enLang = ref(false)

    const verif = reactive({
      img: '',
      code: '',
      value: '',
    })
    const loginType = ref('')
    if (route.query.type) {
      loginType.value = route.query.type
    }
    const verifCode = async () => {
      let res = await axios.get("admin/captcha/generate")
      console.log(res);
      verif.img = res.image
      verif.value = res.id

    }
    const login = async () => {
      try {
        let coord = await getCoord()
        let res = await axios.post("/admin/login/doLogin", {
          username: username.value,
          password: password.value,
          verifyValue: verif.code,
          captchaId: verif.value,
          longitude: coord.lng,
          latitude: coord.lat
        })
        console.log("res", res)
        sessionStorage.removeItem('table_menu')
        setUser(res.Token, res.Id, res.Name, res.RoleId, res.IsMch, res.AgentCode, res.CodePath)
        updateOperators()
        navTo('Home')
        localStorage.setItem('user_info', JSON.stringify(res));
      } catch (e) {
        console.log("err", e)
      }
      // navTo('Home')
    }
    const changeVerifi = () => {

    }

    onMounted(() => {
      if (storage.get('locale') == 'En') {
        enLang.value = true
      }
      verifCode()
      // const scale = () => {
      //   const detectZoom = () => {
      //     var ratio = 0;
      //     ratio = window.outerWidth / window.innerWidth;
      //     if (ratio) {
      //       ratio = Math.round(ratio * 100);
      //     }
      //     return ratio;
      //   }
      //   window.addEventListener('resize', () => {
      //     let ele = document.getElementById("loginbg")
      //     var ratio = 0;
      //     if (window.screen.width < 700) {
      //       return;
      //     }
      //     ratio = window.outerWidth / window.innerWidth;
      //     if (ratio) {
      //       ratio = Math.round(ratio * 100);
      //       ele.style.backgroundSize = detectZoom() + "%";
      //     }
      //   });
      // }
      // scale()
    })
    return {
      username,
      password,
      login,
      loginType,
      verif,
      verifCode,
      appname: cfg.appname,
      enLang
    }
  }
}
</script>

<style lang="scss" scoped>
.clear {
  clear: both;
}

.qrcode {
  width: 200px;
  height: 200px;
}

.qrcode-txt {
  display: block;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  color: #333;
}

.website-url {
  width: 450px;
  height: 100px;
  // border: 1px solid pink;
  position: absolute;
  left: 1%;
  top: 24%;
  z-index: 1;
  cursor: pointer;
}

.login_bg_new {
  position: absolute;
  /* position: relative; */
  z-index: -1;
}

.login_bg_new img {
  position: relative;
  /* position: absolute; */
  width: 100vw;
  height: 100vh;
  /* z-index: -1; */
}

.head_log {
  text-align: center;
  line-height: 1px;
  font-size: 26px;
  min-height: 40px;

  >p {
    color: #fff;
    line-height: 40px;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.en_head_log {
  text-align: center;
  line-height: 1px;
  font-size: 22px;
  min-height: 40px;
}


.login_bg_img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}



.login_black_hg {
  // background: rgba(255, 255, 255, 0.412);
  background: rgba(244, 246, 247, 0.312);
  border-radius: 6px;
  width: 410px;
  height: 410px;
  // padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;
  /* Safari */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 54px;
  color: #fff;
  backdrop-filter: blur(1px);
  z-index: 10;

  .verification {
    display: flex;
    align-items: center;

    .vefi-img {
      filter: contrast(250%);
    }
  }
}


.login_hg_left {
  // flex: 1.8
}

.login_bg {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* height: 100%; */
  // min-width: 800px;

  // background: url(../assets/img/login_bg_new2.png) no-repeat center;
  // background: url(../assets/img/login_bg_new3.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #a0d9f6;
  background-attachment: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.form-group {
  margin-bottom: 20px;
}

.company_logo {
  width: 100px;
}

.company_logo img {

  width: 350px;
  height: 100%;
  margin-bottom: 50px;

}

.login_btn {
  // margin-top: 20px;
  width: 330px;
  height: 40px;
  font-size: 20px;
  letter-spacing: 3px;
  position: relative;
  z-index: 9999;

}

.must_rad {
  font-size: 12px;
}

.login-right-warp {
  position: relative;
  overflow: hidden;
  // background-color: #fff
}

.login-right-warp .login-right-warp-one,
.login-right-warp .login-right-warp-two {
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
}

.login-right-warp .login-right-warp-one:before,
.login-right-warp .login-right-warp-one:after,
.login-right-warp .login-right-warp-two:before,
.login-right-warp .login-right-warp-two:after {
  content: "";
  position: absolute;
  z-index: 1;
}

.login-right-warp .login-right-warp-one:before {
  filter: hue-rotate(0deg);
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #3b82f680);
  animation: loginLeft 3s linear infinite;
}

.login-right-warp .login-right-warp-one:after {
  filter: hue-rotate(60deg);
  top: -100%;
  right: 2px;
  width: 3px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #3b82f680);
  animation: loginTop 3s linear infinite;
  animation-delay: .7s;
}

.login-right-warp .login-right-warp-two:before {
  filter: hue-rotate(120deg);
  bottom: 2px;
  right: -100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(270deg, transparent, #3b82f680);
  animation: loginRight 3s linear infinite;
  animation-delay: 1.4s;
}

.login-right-warp .login-right-warp-two:after {
  filter: hue-rotate(300deg);
  bottom: -100%;
  left: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #3b82f680);
  animation: loginBottom 3s linear infinite;
  animation-delay: 2.1s;
}


@keyframes loginLeft {
  0% {
    left: -100%
  }

  50%,
  to {
    left: 100%
  }
}

@keyframes loginTop {
  0% {
    top: -100%
  }

  50%,
  to {
    top: 100%
  }
}

@keyframes loginRight {
  0% {
    right: -100%
  }

  50%,
  to {
    right: 100%
  }
}

@keyframes loginBottom {
  0% {
    bottom: -100%
  }

  50%,
  to {
    bottom: 100%
  }
}
</style>
