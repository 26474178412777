<template>
  <div class="content">

    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
        <el-button @click="func.showAddDataDialog" type="primary" class="btn-add">{{ $t('g.added') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table @row-click.self="func.showDetails" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="Id">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('billingPolicy.policy_name')">
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" v-if="!isOperator()">
        </el-table-column>
        <el-table-column prop="name_path"  min-width="180" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="CreateAtStr" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column :label="$t('g.operation')" fixed="right" :width="isEnVersion() ? 180 : 160">
          <template #default="scope">
            <el-button @click="func.edit(scope.row)" class="btn-edit" type="primary">{{
              $t('g.edit')
            }}</el-button>
            <el-button @click="func.delete(scope.row.Id)" class="btn-delete" type="primary"
              style="margin-left: 10px;">{{ $t('g.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('billingPolicy.billing_rules')" v-model="formData.dialogVisible" width="85%" align-center>
      <el-form :model="formData.curData" label-width="100px">
        <div class="form-row">
          <el-form-item :title="$t('billingPolicy.rule_name')">
            <el-input class="inp" style="width: 100%;" v-model="formData.curData.Name"
              :placeholder="$t('billingPolicy.enter_rulename')"></el-input>
          </el-form-item>
          <div class="el-form-row">
            <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt" style="width: 100%;">
              <el-cascader @change="func.opt_operator" style="width: 100%;" :clearable="true"
                v-model="formData.curData.AgentCode" :props="formData.props2"
                :options="render.cascade_options_direct" />
            </el-form-item>
            <el-form-item :label="$t('g.operator')" style="width: 100%;">
              <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.CodePath"
                :props="formData.props" :options="render.cascade_options_direct" />
            </el-form-item>
          </div>
          <div style="display: flex;margin-bottom: 30px;">
            <div class="title_label">{{ $t('billingPolicy.fee_settings') }}</div>
            <div style="color: #287fa9;">{{ $t('billingPolicy.settings_txt1') }}</div>
          </div>
          <div class="time_money">
            <div class="label_box box_flex" v-for="(item, index) in render.state" :key="index">
              <div class="title_label"></div>
              <el-tag class="box_tag tag_danger" v-if="index == 0">{{ $t('billingPolicy.peak_period') }}</el-tag>
              <el-tag class="box_tag tag_warning" v-if="index == 1">{{ $t('billingPolicy.peak_hours') }}</el-tag>
              <el-tag class="box_tag tag_success" v-if="index == 2">{{ $t('billingPolicy.normal_period') }}</el-tag>
              <el-tag class="box_tag tag_info" v-if="index == 3">{{ $t('billingPolicy.valley_period') }}</el-tag>
              <el-input v-model="item.electricity_bill" type="number"
                style="width: 100px;margin-right: 10px;"></el-input><span style="margin-right: 10px;">{{
                  $t('billingPolicy.settings_txt2') }}</span>
              <el-input v-model="item.service_charge" type="number"
                style="width: 100px;margin-right: 10px;"></el-input><span>{{ $t('billingPolicy.settings_txt3') }}</span>
            </div>
          </div>
          <div style="display: flex;">
            <div class="title_label">{{ $t('billingPolicy.charging_range') }}</div>
            <div style="color: #287fa9;">{{ $t('billingPolicy.settings_txt4') }}</div>
          </div>
          <div class="time_money">
            <div class="label_box">
              <div class="title_label"></div>
              <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.bucket_type') }}</div>
              <div class="inp_right" style="width: 120px">{{ $t('billingPolicy.start_time') }}</div>
              <div class="inp_right" style="width: 120px">{{ $t('billingPolicy.end_time') }}</div>
              <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.settings_txt2') }}</div>
              <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.settings_txt3') }}</div>
              <div class="inp_right" style="width: 100px">{{ $t('g.total') }}</div>
            </div>
            <div class="money_box" v-for="(item, index) in formData.time_slot" :key="index">
              <div class="title_label"></div>
              <el-select class="inp_right" v-model="item.type" style="width: 100px">
                <el-option v-for="item in render.state" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <!-- :prefix-icon="Clock" -->
              <el-input class="inp_right" v-model="item.strtime" disabled style="width: 120px" />
              <el-select class="inp_right" style="width: 120px" @change="func.change_time(index, item.endtime)"
                v-model="item.endtime">
                <el-option v-for="(item, index) in item.time" :key="index" :label="item" :value="item" />
              </el-select>
              <el-input class="inp_right" disabled :value="render.state[item.type].electricity_bill"
                style="width: 100px" :placeholder="$t('billingPolicy.electricity')">
              </el-input>
              <el-input class="inp_right" disabled :value="render.state[item.type].service_charge" style="width: 100px"
                :placeholder="$t('billingPolicy.service_charge')">
              </el-input>
              <div class="inp_right" style="width: 100px;">{{ $t('g.sum') }}:{{
                func.num_add(render.state[item.type].electricity_bill, render.state[item.type].service_charge) }}{{
                  $t('billingPolicy.settings_txt5') }}
              </div>
              <el-button class="btn-add btn" @click="func.add(index)" :icon="Plus" v-show="item.add" />
              <el-button class="btn-delete btn" @click="func.del(index)" :icon="Minus" v-show="item.del" />
            </div>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, watch } from 'vue'
import { shortcuts, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator, isPath, isAdmin, isEnVersion } from '@/data/token'
import { Clock, Plus, Minus } from '@element-plus/icons-vue'
export default {
  name: 'ExpensesCombo',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const render = reactive({
      shortcuts,
      cascade_options_direct,
      state: [
        {
          label: t('billingPolicy.peak_period'),
          value: 0,
          electricity_bill: 1.26,
          service_charge: 0.17
        },
        {
          label: t('billingPolicy.peak_hours'),
          value: 1,
          electricity_bill: 0.78,
          service_charge: 0.34
        },
        {
          label: t('billingPolicy.normal_period'),
          value: 2,
          electricity_bill: 0.78,
          service_charge: 0.31
        },
        {
          label: t('billingPolicy.valley_period'),
          value: 3,
          electricity_bill: 0.36,
          service_charge: 0.47
        }
      ],
    })
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: 0,
      valType: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 500,
      valOperator: 0,
      devType: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      IsStartWith: 1,
      is_opt: false,
      time_slot: [],
      form_type: 'add'

    })
    watch(() => formData.curData.CodePath, () => {
      //let simplerWay = formData.curData.agentPath.slice(0, -1).split('/').pop(); 
      func.getDevType(formData.curData.CodePath)
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      async getDevType(CodePath) {
        let params = {}
        params.CodePath = CodePath
        params.getAll = 1
        let res = await axios.get('/admin/box/devtypes', { params })
        if (res) {
          formData.devType = res
        }
      },
      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        formData.dialogDisplayVisible = true;
      },
      edit(row, column, e) {
        formData.curData = row
        // formData.curData.CodePath = row.CodePath
        // formData.curData.agentCode = row.AgentCode 
        render.state = JSON.parse(row.BillBase)
        formData.time_slot = JSON.parse(row.BillTime)
        for (let i = 0; i < formData.time_slot.length; i++) {
          formData.time_slot[i].time = func.generateTimes(formData.time_slot[i].strtime)
          // formData.time_slot[i].electricity_bill = formData.time_slot[i].electricity_bill * 1000
          // formData.time_slot[i].service_charge = formData.time_slot[i].service_charge * 1000
        }
        render.state.map((item) => {
          item.electricity_bill = item.electricity_bill / 1000
          item.service_charge = item.service_charge / 1000
        })
        formData.form_type = 'edit'
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      generateTimes(startTime) {
        let times = [];
        let start = startTime.split(':').map(Number);
        let end = [24, 0];
        let current = [...start];
        while (current[0] < end[0] || (current[0] === end[0] && current[1] <= end[1])) {
          times.push(current.map(n => n.toString().padStart(2, '0')).join(':'));
          current[1] += 30;
          if (current[1] >= 60) {
            current[0] += Math.floor(current[1] / 60);
            current[1] = current[1] % 60;
          }
        }
        return times;
      },
      showAddDataDialog() {
        func.init_time()
        formData.curData = {}
        // formData.curData = {
        //   CodePath: operatorList.length ? operatorList[0].value : "",
        // }

        // formData.curData.Type = 1 
        formData.form_type = 'add'
        formData.dialogVisible = true
      },
      async updateData() {

        if (formData.curData.Name == '' || !formData.curData.Name) {
          ElMessage({
            type: 'error',
            message: '请填写完整'
          })
          return
        }
        let isnull = formData.time_slot.some(obj => Object.values(obj).some(value => value === null || value === ''));
        if (isnull) {
          ElMessage({
            type: 'error',
            message: '请填写完整'
          })
          return
        }

        let length = formData.time_slot.length - 1
        if (formData.time_slot[length].endtime != '24:00') {
          ElMessage({
            type: 'error',
            message: '最后必须以24时结尾'
          })
          return
        }


        let billTime = JSON.parse(JSON.stringify(formData.time_slot))
        let billBase = JSON.parse(JSON.stringify(render.state))
        for (let i = 0; i < billTime.length; i++) {
          let type = billTime[i].type
          billTime[i].electricity_bill = Number(billBase[type].electricity_bill) * 1000
          billTime[i].service_charge = Number(billBase[type].service_charge) * 1000
          billTime[i].time = []
        }
        billBase.map((item) => {
          item.electricity_bill = Number(item.electricity_bill) * 1000
          item.service_charge = Number(item.service_charge) * 1000
        })
        try {
          let params = {
            Name: formData.curData.Name,
            CodePath: formData.curData.CodePath,
            AgentCode: formData.curData.AgentCode,
            BillTime: JSON.stringify(billTime),
            BillBase: JSON.stringify(billBase),
          }
          if (formData.form_type == 'edit') {
            console.log(billTime, '数值')
            params.Id = formData.curData.Id
            await axios.post('/admin/billbaseset/edit', params)
          } else {
            await axios.post('/admin/billbaseset/add', params)
          }
        } catch (e) {

        }
        formData.dialogVisible = false
        func.loadData()
      },
      async delete(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/billbaseset/delete', {
              Id
            }).then((res) => {
              if (res == '删除成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })
      },
      change_time(index, time) {
        let oldtime = func.convertTimeToTimestamp(time)
        let indicesToDelete = [];
        for (let i = (index + 1); i < formData.time_slot.length; i++) {
          let contrast_time = func.convertTimeToTimestamp(formData.time_slot[i].endtime)
          formData.time_slot[i].time = func.generateTimes(time)
          if (oldtime >= contrast_time) {
            indicesToDelete.push(i)
          }
        }
        indicesToDelete.sort((a, b) => b - a);
        indicesToDelete.forEach(index => {
          formData.time_slot.splice(index, 1);
        });
        formData.time_slot[index + 1].strtime = time

      },
      opt_operator() {
        formData.curData.DevTypeStr = ''
      },
      convertTimeToTimestamp(time) {
        const parts = time.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        // 创建当天的新日期，并设置指定的小时和分钟
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0, 0); // 将秒和毫秒设置为0
        // 获取时间戳 
        const timestamp = date.getTime();
        return timestamp;
      },
      num_add(num1, num2) {
        num1 = Number(num1)
        num2 = Number(num2)
        // const result = Math.round((num1  + num2 ) * 100) / 100;
        const result = num1 + num2
        return result.toFixed(2);
      },
      del(index) {
        formData.time_slot.splice(index, 1)
      },
      add(index) {
        let obj = {
          strtime: formData.time_slot[index].endtime,
          endtime: formData.time_slot[index].endtime,
          electricity_bill: Number(render.state[0].electricity_bill) * 1000,
          service_charge: Number(render.state[0].service_charge) * 1000,
          type: 0,
          add: true,
          del: true,
          time: func.generateTimes(formData.time_slot[index].endtime)
        }
        formData.time_slot.splice(index + 1, 0, obj)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOperator) {
            params.CodePath = formData.valOperator
          }
          // if (formData.valTime) {
          //   params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          //   params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          // }
          let res = await axios.get(`/admin/billbaseset/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt)
          })
          console.log(res, '返回数据')
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      },
      init_time() {
        formData.time_slot = []
        formData.time_slot.push(
          { strtime: '00:00', endtime: '08:00', electricity_bill: 0, service_charge: 0, type: 3, add: true, del: false, time: [] },
          { strtime: '08:00', endtime: '10:00', electricity_bill: 0, service_charge: 0, type: 1, add: true, del: true, time: [] },
          { strtime: '10:00', endtime: '16:00', electricity_bill: 0, service_charge: 0, type: 0, add: true, del: true, time: [] },
          { strtime: '16:00', endtime: '24:00', electricity_bill: 0, service_charge: 0, type: 2, add: true, del: true, time: [] },
        )
        formData.time_slot[0].time = func.generateTimes('00:00')
        formData.time_slot[1].time = func.generateTimes('08:00')
        formData.time_slot[2].time = func.generateTimes('10:00')
        formData.time_slot[3].time = func.generateTimes('16:00')
      }
    }
    onMounted(() => {
      func.init_time()
      func.loadData()
      func.getDevType()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    return {
      func,
      render,
      isOperator,
      isAdmin,
      formData,
      isEnVersion,
      isPath,
      Clock,
      Plus,
      Minus
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;


        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }


  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

.form-row {
  text-align: left;
  padding: 0 60px;

  .inp {
    width: 500px;
  }

  .inp_right {
    margin-right: 15px;
  }

  .label_box {
    display: flex;
    margin-bottom: 15px
  }
}

.title_label {
  width: 100px;
  text-align: right;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.money_box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.time_money {
  margin-top: 30px
}

:deep .btn .el-icon svg {
  height: 1em;
  width: 1em;
  color: #fff;
}

.box_flex {
  align-items: center;
}

.box_tag {
  margin-right: 20px;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
}

.tag_danger {
  color: rgb(255, 255, 255);
  border-color: rgb(245, 108, 108);
  background-color: rgb(245, 108, 108);
}

.tag_warning {
  color: rgb(255, 255, 255);
  border-color: rgb(230, 162, 60);
  background-color: rgb(230, 162, 60);
}

.tag_success {
  color: rgb(255, 255, 255);
  border-color: rgb(103, 194, 58);
  background-color: rgb(103, 194, 58);
}

.tag_info {
  color: rgb(255, 255, 255);
  border-color: rgb(144, 147, 153);
  background-color: rgb(144, 147, 153);
}

.el-form-row {
  display: flex;
}
</style>