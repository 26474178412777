<template>
  <div class="content">
    <!-- <div class="avatar-box">
      <div class="name">{{ formData.profile.Name }}</div>
      <img class="avatar" src="@/assets/logo.png" />
    </div> -->
    <div class="card-box">
      <el-descriptions :title="$t('profile.personalDetails')" :column="3" border>
        <template #extra>
          <el-button type="primary" @click="func.onEditPassword">{{ $t('member.modify_password')
            }}</el-button>
        </template>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <User />
              </el-icon>
              {{ $t('rights.username') }}
            </div>
          </template>
          {{ formData.profile.Username }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Avatar />
              </el-icon>
              {{ $t('bill.nickname') }}
            </div>
          </template>
          {{ formData.profile.Name }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <iphone />
              </el-icon>
              {{ $t('member.mobile') }}
            </div>
          </template>
          {{ formData.profile.Mobile }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Message />
              </el-icon>
              {{ $t('member.email') }}
            </div>
          </template>
          {{ formData.profile.Email }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <View />
              </el-icon>
              {{ $t('rights.role') }}
            </div>
          </template>
          {{ formData.profile.Role?.Title }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Collection />
              </el-icon>
              {{ $t('profile.roleDes') }}
            </div>
          </template>
          {{ formData.profile.Role?.Description }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <tickets />
              </el-icon>
              {{ $t('g.agent_code') }}
            </div>
          </template>
          {{ formData.profile.AgentCode }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Place />
              </el-icon>
              {{ $t('g.operator') }}
            </div>
          </template>
          {{ formData.profile.CodePath }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Timer />
              </el-icon>
              {{ $t('profile.curLoginTime') }}
            </div>
          </template>
          {{ formData.profile.LoginTimeAtStr }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Timer />
              </el-icon>
              {{ $t('profile.lastLoginTime') }}
            </div>
          </template>
          {{ formData.profile.LastLoginAtStr }}
        </el-descriptions-item>
        <el-descriptions-item class="des-item">
          <template #label>
            <div class="cell-item">
              <el-icon class="icon">
                <Timer />
              </el-icon>
              {{ $t('g.create_at') }}
            </div>
          </template>
          {{ formData.profile.CreateAtStr }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-dialog :title="$t('member.modify_password')" v-model="formData.editpassword" width="40%" center align-center
      class="pwd-dialog">
      <div class="box">
        <el-input :placeholder="$t('member.new_password_ph')" class="old-pwd" type="password"
          v-model="formData.password"></el-input>
        <el-input :placeholder="$t('member.repassword')" class="new-pwd" type="password"
          v-model="formData.repassword"></el-input>
        <el-button class="btn-edit" type="info" @click="func.modifyPassword">{{ $t('member.modify_password')
          }}</el-button>
      </div>


    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { toDay } from '@/common/func'
import { ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'
import axios from 'axios'

export default {
  name: 'MemberProfile',
  setup() {
    const t = i18n.global.t
    const formData = reactive({
      activeName: 'first',
      password: "",
      repassword: "",
      editpassword: false,
      profile: {
        AgentInfo: {}
      },
    })
    const func = {
      onEditPassword() {
        formData.editpassword = true;
      },
      async modifyPassword() {
        if (!formData.password.trim()) {
          return ElMessage({
            type: 'error',
            message: t('member.new_password_ph')
          })
        }
        if (formData.password != formData.repassword) {
          return ElMessage({
            type: 'error',
            message: t('member.repassword_not_same')
          })
        }
        let params = {
          password: formData.password
        }
        try {
          await axios.post("/admin/manager/editPassword", params)
          formData.password = ""
          formData.repassword = ""
          formData.editpassword = false;
          return ElMessage({
            message: t("g.success"),
            type: 'success'
          })
        } catch (e) {

        }
      }
    }
    const loadData = async () => {
      let res = await axios.get("/admin/userDetail")
      res.CreateAtStr = toDay(res.CreateTime)
      res.LastLoginAtStr = toDay(res.LastLoginTime)
      res.LoginTimeAtStr = toDay(res.LoginTime)
      // if (res.AgentInfo.WxRate) {
      //   res.AgentInfo.WxRate *= 100
      // }
      formData.profile = res
    }
    onMounted(() => {
      loadData()
    })
    return {
      formData,
      func
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  height: calc(100% - 5rem);

  .card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    // margin-bottom: 1.25rem;
    // margin-bottom: 1.25rem;

    :deep .cell-item {
      height: 46px;
      line-height: 46px;
      font-size: 16px;
      display: flex;
      justify-content: left;
      align-items: center;

      .icon {
        font-size: 20px;
        margin-right: 12px;
      }
    }

    :deep .el-descriptions .el-descriptions__label {
      width: 200px;
    }

    :deep .el-descriptions .el-descriptions__content {
      width: 200px;
    }

    :deep .tabs {
      // padding: 32px;
      // color: #05142d;
      // font-size: 32px;
      // font-weight: 600;

      .el-tabs__item.is-active {
        background-color: #409eff;
        color: #fff !important;
      }
    }



  }

  .pwd-dialog {
    .box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: pink;
      // margin-left: 30px;
    }

    .old-pwd,
    .new-pwd {
      width: 200px;
      margin-left: 20px;
      text-align: center;
    }

    .btn-edit {
      // margin-top: 20px;
      margin-left: 20px;
      // float: right;
    }
  }
}
</style>
