<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>

        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('bill.type') }}</span>
          <el-select class="inp" size="small" v-model="formData.valType" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevNo" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('bill.nickname') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('bill.nickname')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="80">
        </el-table-column>
        <el-table-column prop="UserName" :label="$t('bill.user')" width="100">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="BusinessSn" :label="$t('bill.no')" width="200">
        </el-table-column>
        <el-table-column prop="Money" width="80" :label="$t('bill.money')">
        </el-table-column>
        <el-table-column width="100" :label="$t('order.combo_type')">
          <template #default="{ row }">
            {{ render.orderType[row.Type - 1] }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" width="180" :label="$t('order.remark')">
        </el-table-column>
        <el-table-column prop="DeviceId" :label="$t('device.no')" min-width="200">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>

      </el-table>
      <div class="pagination">
        <div class="title">
          {{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <span class="title">
          <div class="item">
            <div class="key">{{ $t("bill.total_charge") }}:</div>
            <div class="val">{{ formData.ChargeMoney }}</div>
          </div>
          <div class="item">
            <div class="key">{{ $t("bill.total_consume") }}:</div>
            <div class="val">{{ formData.ConsumeMoney }}</div>
          </div>
          <div class="item">
            <div class="key">{{ $t("bill.total_deposit") }}:</div>
            <div class="val">{{ formData.DepositMoney }}</div>
          </div>
        </span>

        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'

export default {
  name: 'BillRecord',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('bill.types.all'),
        value: 0
      },
      {
        label: t('bill.logTypes.consume'),
        value: '1'
      },
      {
        label: t('bill.logTypes.charge'),
        value: '2'
      },
      {
        label: t('bill.logTypes.deposit'),
        value: '3'
      },
    ]
    const orderType = [t('home.pay'), t('home.topUp'), t('home.deposit'), t('home.fine'),]
    const render = {
      statuses,
      operators,
      shortcuts,
      cascade_options_direct,
      orderType
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: "",
      valType: 0,
      valDevNo: "",
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      ChargeMoney: 0,
      ConsumeMoney: 0,
      DepositMoney: 0,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
    })
    const func = {
      async expand(row, state) {
        if (state.length) {
        }
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valStatus) {
            params.remark = formData.valStatus
          }
          if (formData.valName) {
            params.nickname = formData.valName
          }
          if (formData.valCode) {
            params.agentCode = formData.valCode
          }
          if (formData.valDevNo) {
            params.device_id = formData.valDevNo
          }
          if (formData.valType) {
            params.type = formData.valType
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0] + " 00:00:00") / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/moneyLog/get`, {
            params
          })
          formData.total = res.Total
          res.List.LogInfo.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt)
          })
          formData.tableData = res.List.LogInfo
          formData.ChargeMoney = res.List.ChargeMoney
          formData.ConsumeMoney = res.List.ConsumeMoney
          formData.DepositMoney = res.List.DepositMoney
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .orders {
      .order {
        display: flex;
        margin-bottom: 0.5rem;
        align-items: center;

        >div {
          margin-right: 1rem;
        }
      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;



      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;


        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  .pagination {
    text-align: right;
    margin-top: 1.25rem;

    >.title {
      font-size: 1rem;
      // font-weight: 500;
      display: flex;
      flex-wrap: wrap;

      >.item {
        display: flex;
        margin-right: 2rem;

        .key {
          // margin-right: 0.5rem;
        }

        .val {
          // font-weight: 700;
          // color: $color-danger;
        }
      }
    }
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}
</style>
