<template>
  <div class="component">
    <div class="chart-box">
      <div :id="formData.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import i18n from '@/data/i18n/index'
import axios from 'axios'
export default {
  name: 'consume',
  props:{
    agentCode:[String,Number],
    cycle:{
      type:Number,
      default:1,
    },
    startAt:{
      type:Date,
      default:null
    },
    id:{
      type:String,
      default:"consume"
    }
  },
  setup (props) {
    const t = i18n.global.t
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    const id = "chartid-user-" + props.id
    const formData = reactive({
      id
    })
    const initChart = () => {
      const chartDom = document.getElementById(id)
      myChart = echarts.init(chartDom)
      loadData()
    }

    const loadData = async () => {
      if(!props.startAt)return
      const params = {
        startAt:props.startAt.getTime() / 1000,
        cycle:props.cycle,
        CodePath:props.agentCode || "",
      }
      let res = await axios.get("/admin/analyze/consume/trend",{
        params
      })

      const legend = [t('device.types.ebox'), t('device.types.cbox'),t('device.types.pile')]
      const xAxis = []
      const data = [[], [],[]]
      for (let i = 0; i < res.ebox.length; i++) {
        xAxis.push(res.ebox[i].Key)
        data[0].push(res.ebox[i].Val)
        data[1].push(res.cbox[i].Val)
        data[2].push(res.pile[i].Val)
      }
      const option = {
        title: {
          text: t('chart.trends.consume')
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: legend[0],
            type: 'line',
            data: data[0]
          },
          {
            name: legend[1],
            type: 'line',
            data: data[1]
          },
          {
            name: legend[2],
            type: 'line',
            data: data[2]
          }
        ]
      }

      option && myChart.setOption(option)
    }
    onMounted(() => {
      initChart()
    })
    return {
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
