<template>
  <!-- <div class="component" v-show="showMainMenu" :style="{width:isEnVersion.status?'15.5rem':'10rem'}"> -->
  <div class="component">
    <div class="logo" @click="navTo('Home')" :style="{ width: logoWidth }">
      <img src="@/assets/logo.png" />
      <span class="logo_name" v-show="nameType">{{ $t('appname') }}</span>
    </div>
    <!-- @open="handleOpen"
    @close="handleClose" -->
    <!-- <el-menu :default-active="active" class="el-menu" :collapse="menuType">
      <el-sub-menu :index="item2.id" :key="index" v-for="(item2, index) in userMenuList.data">
        <template #title>
          <el-icon>
            <Component :is="item2.icon" />
          </el-icon>
          <span>{{ item2.title }}</span>
        </template>
<el-menu-item :index="item3.name" :key="index2" v-for="(item3, index2) in item2.item" @click="navTo2(item3)">
  <span style="margin-left: 4px;">{{ item3.title }}</span>
</el-menu-item>
</el-sub-menu>
</el-menu> -->
    <el-menu :default-active="active" class="el-menu" :collapse="menuType">
      <template :key="index" v-for="(item2, index) in userMenuList.data">
        <el-menu-item :index="item2.id" v-if="item2.item.length == 0" @click="navTo1(item2)" class="center-patch">
          <el-icon>
            <Component :is="item2.icon" />
          </el-icon>
          <span>{{ item2.title }}</span>
        </el-menu-item>
        <el-sub-menu :index="item2.id" v-else>
          <template #title>
            <el-icon>
              <Component :is="item2.icon" />
            </el-icon>
            <span>{{ item2.title }}</span>
          </template>
          <el-menu-item :index="item3.name" :key="index2" v-for="(item3, index2) in item2.item" @click="navTo2(item3)">
            <span style="margin-left: 4px;">{{ item3.title }}</span>
          </el-menu-item>
        </el-sub-menu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import { navTo } from '@/common/func'
import { showMainMenu, toggleMainMenu, isMiniScreen } from '@/data/default'
import { userMenuList } from '@/data/menu'
import storage from '@/common/storage'
import { useRoute } from 'vue-router'
export default {
  name: 'PublicLeft',
  props: {
    menuType: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute()
    const activeName = ref('0')
    const isEnVersion = reactive({
      status: false,
    })
    let active = ref('')
    let type = false
    let nameType = ref(true)
    let logoWidth = ref('215px')
    if (storage.get('locale') == 'En') {
      logoWidth.value = '230px';
    }
    watch(() => route, (item) => {
      if (active.value == item.name || type) return
      active.value = item.name
    }, { immediate: true, deep: true })

    watch(() => props.menuType, (neval) => {
      if (neval == true) {
        nameType.value = false
        logoWidth.value = "auto"
      } else {
        nameType.value = true
        logoWidth.value = "215px"
      }
    }, { deep: true })

    onMounted(() => {
      if (storage.get('locale') == 'En') {
        isEnVersion.status = true;
      }
      setTimeout(() => {
        console.log('菜单值', userMenuList)
      }, 2000)

    })
    return {
      userMenuList,
      navTo2: (item) => {
        type = true
        if (isMiniScreen.value) {
          toggleMainMenu()
        }
        navTo(item.name)
        setTimeout(() => {
          type = false
        }, 100)
      },
      navTo1: (item) => {
        window.open('/' + item.name, '_blank');
      },
      navTo,
      showMainMenu,
      activeName,
      isEnVersion,
      active,
      nameType,
      logoWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu {
  // padding: 0 30px;
  // background-color: #fff;
  // border-right: none;
  height: calc(100vh - 59px);
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;
}

// :deep .el-sub-menu__title {
//     color: #bfcbcf;
// }
:deep .el-menu {
  background-color: var(--menu-bg-color);
  padding: 0 5px;
}

// :deep .el-sub-menu__title:hover {
//   background-color: #81bcf6;
//   color: #fff;
// }

:deep .el-menu-item.is-active {
  color: #fff;
  background-color: var(--menu-active-color);
}

:deep .el-sub-menu .el-menu-item {
  height: 40px;
  line-height: 40px;
}

:deep .el-menu-item:hover {
  background-color: var(--menu-hover-color);
  color: #fff;
}

:deep .el-menu-item {
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  // margin-left: -20px;
}

:deep .el-sub-menu__title {
  height: 46px;
  line-height: 46px;
  padding: 0 12px;
}

:deep .el-menu--collapse .center-patch {
  margin-left: -8px;
}

.component {
  background-color: var(--menu-bg-color);
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  // font-weight: bold;
  cursor: pointer;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 12px;

  >img {
    width: 2.5rem;
    height: auto;
    margin-right: 0.625rem;
  }
}

::-webkit-scrollbar {
  width: 6px;
  /* 滚动条宽度 */
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #fff;
  /* 轨道颜色 */
}

/* 自定义滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: rgba($color: #909399, $alpha: 0.2);
}

/* 当hover或active状态时，自定义滑块的颜色 */
::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #909399, $alpha: 0.5);
}

// .component:hover {
//   overflow-y: auto; /* 鼠标移入时显示垂直滚动条 */
// }
// @media screen and (max-width:600px) {
//   .logo_name{
//     display: none;
//   }
//   .logo{
//     width: auto;
//   }
// }</style>
