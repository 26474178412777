<template>
  <div class="content">
    <div id="map"></div>
    <div class="map-filter">
      <!-- <div class="item" v-if="!isOperator()">
        <span class="label">{{$t('g.operator')}}</span>
        <el-select size="small" class="s-select" v-model="valOperator" :placeholder="$t('g.choose')">
            <el-option
              v-for="item in operators"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
      </div> -->
      <div class="item">
        <!-- <span class="label">{{ $t('g.operator') }}</span> -->
        <el-cascader style="width: 210px;margin-right: 0.5rem;" :clearable="true" v-model="valOperator" :props="props"
          :options="cascade_options_direct" :placeholder="$t('decision.tips.agent_code')" />
      </div>
      <div class="item">
        <!-- <span class="label">{{$t('decision.type')}}</span> -->
        <el-select class="s-select" v-model="valType" :placeholder="$t('g.choose')" placeholder="请选择类型"
          style="width: 210px;margin-right: 0.5rem;">
          <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item" v-if="valType == 2">
        <!-- <span class="label">{{$t('decision.cycle')}}</span> -->
        <el-select class="s-select" v-model="valCircle" :placeholder="$t('g.choose')" placeholder="请选择周期"
          style="width: 210px;margin-right: 0.5rem;">
          <el-option v-for="item in circles" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item" v-if="valType == 2">
        <!-- <span class="label">{{$t('decision.time')}}</span> -->
        <el-date-picker style="width: 210px;margin-right: 0.5rem;" v-model="valTime"
          :type="valCircle == 1 ? 'year' : (valCircle == 2 ? 'month' : 'day')" placeholder="请选择时间">
        </el-date-picker>
      </div>
    </div>
    <div class="map-options">
      <el-radio v-if="valType != 1" v-model="type" label="all">{{ $t('device.types.all') }}</el-radio>
      <el-radio v-if="valType != 1" v-model="type" label="cpbox">{{ $t('device.types.cpbox') }}</el-radio>
      <el-radio v-if="valType != 1" v-model="type" label="ebox">{{ $t('device.types.ebox') }}</el-radio>
      <el-radio v-if="valType != 1" v-model="type" label="cbox">{{ $t('device.types.cbox') }}</el-radio>
      <el-radio v-if="valType != 1" v-model="type" label="pile">{{ $t('device.types.pile') }}</el-radio>
      <el-checkbox v-model="poiFlag">{{ $t('decision.poi') }}</el-checkbox>
      <!-- <el-radio v-model="mode" label="2D">2D</el-radio> -->
      <!-- <el-radio v-model="mode" label="3D">3D</el-radio> -->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch, onBeforeUnmount } from 'vue'
import axios from 'axios'
import { getCoord } from '@/data/default'
import { operators, cascade_options_direct } from '@/data/default'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'DecisionHot',
  setup() {
    const t = i18n.global.t
    let map = null
    let mapHeat = null
    const poiFlag = ref(false)
    const type = ref('all')
    const mode = ref('2D')
    const valOperator = ref(0)
    const valType = ref(0)
    const valCircle = ref(1)
    const valTime = ref('')
    const types = [
      {
        label: t('decision.types.distribution_device'),
        value: 0
      }, {
        label: t('decision.types.distribution_member'),
        value: 1
      }, {
        label: t('decision.types.distribution_order'),
        value: 2
      }

    ]
    const circles = [
      {
        label: t('decision.cycles.by_year'),
        value: 1
      }, {
        label: t('decision.cycles.by_month'),
        value: 2
      }, {
        label: t('decision.cycles.by_day'),
        value: 3
      }
    ]
    const props = {
      children: "Children", expandTrigger: 'hover',
      label: "Name",
      value: "CodePath",
      emitPath: false,
      checkStrictly: true
    }
    let IsStartWith = ref(1)

    watch(mode, () => {
      switch2Dor3D(mode.value)
    })
    watch([() => valType.value, () => type.value, () => valOperator.value, () => valTime.value], () => {
      console.log("search...")
      loadData()
    }, { deep: true })
    watch(poiFlag, () => {
      showPOI(poiFlag.value)
    })
    // 2D或者3D展示
    const switch2Dor3D = (type = '2D') => {
      // let height = 40
      // if (type === '2D') {
      //   height = 0
      // }
      // mapHeat.setHeight(height)
    }
    // 是否显示地名
    const showPOI = (flag = true) => {
      const features = ['bg', 'road']
      if (flag) {
        features.push('point')
      }
      map.setFeatures(features)
    }
    let layer = null
    const initMap = async () => {
      const coord = await getCoord()
      const AMap = window.AMap
      const center = [coord.lng, coord.lat]
      // 初始化地图
      map = new AMap.Map('map', {
        zoom: 12, // 设置地图缩放级别
        features: ['bg', 'road'],
        center, // 设置地图中心点坐标
        mapStyle: 'amap://styles/a44ad00f4a0fdc454e06be32f8ded64e',
        viewMode: '2D'
      })
      // 初始化热力图并添加至map图层
      layer = new Loca.HeatmapLayer({
        map: map,
      });
      layer.setOptions({
        style: {
          radius: 16,
          color: {
            0.1: '#2A85B8',
            0.2: '#16B0A9',
            0.3: '#29CF6F',
            0.4: '#5CE182',
            0.5: '#7DF675',
            0.6: '#FFF100',
            0.7: '#FAA53F',
            1: '#D04343',
          }
        }
      });
      await loadData()
    }
    const loadData = async () => {
      const params = {}
      if (valOperator.value != 0) {
        params.CodePath = valOperator.value
      }
      params.IsStartWith = IsStartWith.value
      if (valType.value != 0) {
        params.type = valType.value
      }
      if (type.value != "" && type.value != "all") {
        let typeInt = 0
        switch (type.value) {
          case "ebox":
            typeInt = 1;
            break;
          case "cbox":
            typeInt = 2;
            break;
          case "cpbox":
            typeInt = 4;
            break;
          case "pile":
            typeInt = 3;
            break;
        }
        params.devType = typeInt
      }
      if (valTime.value) {
        let startAt = valTime.value.getTime() / 1000
        let endAt = 0
        if (valCircle.value == 1) {
          endAt = startAt + 24 * 60 * 60 * 365
        } else if (valCircle.value == 2) {
          let days = 30
          let month = valTime.value.getMonth() + 1
          if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
            days = 31
          } else if (month == 2) {
            let year = valTime.value.getFullYear()
            days = year % 4 === 0 ? 29 : 28
          }
          console.log("days", days)
          endAt = startAt + 24 * 60 * 60 * days
        } else {
          endAt = startAt + 24 * 60 * 60
        }
        params.startAt = startAt
        params.endAt = endAt
      }
      console.log("params", params)
      //未满足搜索条件
      if (params.type == 3 && !params.startAt) {
        return
      }
      const res = await axios.get("/admin/hot", {
        params
      })
      const data = []
      for (let item of res) {
        if (item.Log != 0 && item.Lat != 0) {
          data.push({
            coordinate: [item.Log, item.Lat],
            count: 1
          })
        }

      }
      layer.setData(data, {
        lnglat: 'coordinate',
        value: 'count'
      })
      layer.render()
    }
    onMounted(() => {

    })
    initMap()
    onBeforeUnmount(() => {
      // 销毁地图，防止卡顿
      map.destroy()
    })

    return {
      valOperator,
      operators,
      valType,
      types,
      valCircle,
      circles,
      valTime,
      poiFlag,
      type,
      isOperator,
      mode,
      props,
      cascade_options_direct
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  height: 100%;
  padding: 0px;

  #map {
    position: absolute;
    width: 100%;
    // height: 100%;
    height: calc(100% - 30px);
  }
}

.map-filter {
  position: absolute;
  top: 15px;
  left: 12px;
  z-index: 9;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;

  // padding: 0 4rem;
  // justify-content: space-between;
  .s-select {
    width: 13.7rem;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    .label {
      color: #fff;
      width: 3rem;
      margin-right: 0.625rem;
    }
  }
}

.map-options {
  position: absolute;
  bottom: 2.6rem;
  right: 1.25rem;
  background: #fff;
  z-index: 9999;
  padding: 0.275rem 0.8rem;
  border-radius: 0.5rem;
}
</style>
