<template>
  <div class="component">
    <div class="rights">
      {{ $t('common.rights') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicFooter'
}
</script>

<style lang="scss" scoped>
.component {
  position: relative;

  .rights {
    background-color: #f2f6f9;
    // position: fixed;
    // right: 0;
    // bottom: 0;
    // width: calc(100% - 16.5rem);
    width: 100%;
    // height: 3rem;
    z-index: 1100;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    // padding-left: 1.25rem;
    font-size: 0.875rem;
    justify-content: center;
    // position: relative;
    // top: -100px;
    margin-bottom: 3.6rem;
  }
}

@media screen and (max-width:800px) {
  .component {
    .rights {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}
</style>
