<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
      </div>
      <div class="btn-ct">
        <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">新建</el-button>
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" class="el-table">
        <el-table-column prop="Id" label="#">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('restriction_rule.name')">
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('restriction_rule.description')">
        </el-table-column>
        <el-table-column prop="Agent.Name" :label="$t('restriction_rule.channel_restrict')">
        </el-table-column>
        <el-table-column prop="DevName" :label="$t('restriction_rule.product_restrict')">
        </el-table-column>
        <el-table-column prop="Combo.Name" :label="$t('restriction_rule.combo_restrict')">
        </el-table-column>
        <el-table-column :width="150" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag size="large" type="success" @click="func.editRule(scope.row)">编辑</el-tag>
            <el-tag size="large" type="warning" style="margin-left: 0.5rem;"
              @click="func.deleteRule(scope.row.Id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="formData.operateType == 1 ? '新建' : '编辑'" v-model="formData.dialogVisible" class="new-el-dialog" center>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form label-width="120px">
            <el-form-item :label="$t('restriction_rule.name')" width="100px">
              <el-input v-model="formData.title" />
            </el-form-item>
            <el-form-item :label="$t('restriction_rule.description')" width="350px">
              <el-input v-model="formData.description" />
            </el-form-item>
            <el-form-item :label="$t('restriction_rule.channel_restrict')" width="350px">
              <el-select class="inp" size="small" v-model="formData.channel_restrict" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.operators" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('restriction_rule.product_restrict')" width="350px">
              <el-select class="inp" size="small" v-model="formData.product_restrict" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.pile" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('restriction_rule.combo_restrict')" width="350px">
              <el-select class="inp" size="small" v-model="formData.combo_restrict" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.combo" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="func.updateData()">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from 'vue'
import activityStyle from '@/components/activityMode/activityStyle.vue'
import i18n from '@/data/i18n/index'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import axios from "axios"
import { user, isOperator } from '@/data/token'
import { shortcuts, operators, pile, combo } from '@/data/default'
export default {
  name: 'restrictionRule',
  components: {
    activityStyle
  },
  setup() {
    const t = i18n.global.t
    // console.log(JSON.stringify(combo));

    const render = {
      combo,
      pile,
      operators,
      shortcuts,

    }
    const formData = reactive({
      tableData: [],
      title: '',
      operateType: 1,//操作类型 1 新增 2 编辑
      id: 0,
      description: '',
      channel_restrict: '',
      product_restrict: '',
      combo_restrict: '',
      dialogVisible: false,
      total: 1,
      curPage: 1,
      pageSize: 10,
    })
    const func = {
      newActivity() {
        formData.operateType = 1;
        formData.id = ''
        formData.title = ''
        formData.description = ''
        formData.product_restrict = ''
        formData.combo_restrict = ''
        formData.channel_restrict = ''
        formData.dialogVisible = true;
      },
      async updateData() {
        // console.log(JSON.stringify(formData.channel_restrict));

        if (formData.operateType == 1) {
          try {
            const params = {
              name: formData.title,
              remark: formData.description,
              agentId: formData.channel_restrict,
              devId: formData.product_restrict,
              commId: formData.combo_restrict,
            }
            let res = await axios.post('/admin/couponlimit/add', params)
            if (res == '成功') {
              ElMessage({
                type: 'success',
                message: t('g.success')
              })
            } else {
              ElMessage({
                type: 'error',
                message: t('err_code.20017')
              })
            }
          } catch (e) {
          }
        } else if (formData.operateType == 2) {
          try {
            const params = {
              id: formData.id,
              name: formData.title,
              remark: formData.description,
              agentId: formData.channel_restrict,
              devId: formData.product_restrict,
              commId: formData.combo_restrict,

            }
            let res = await axios.post('/admin/couponlimit/edit', params)
            // console.log(JSON.stringify(res));
            if (res == '操作成功') {
              ElMessage({
                type: 'success',
                message: t('g.success')
              })
            } else {
              ElMessage({
                type: 'error',
                message: t('err_code.20017')
              })
            }
          } catch (e) {
          }
        }

        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async editRule(row) {
        formData.operateType = 2;
        try {
          // console.log(JSON.stringify(row));

          let params = {}
          if (row) {
            // params.id = row.id 
            // params.name = row.name 
            // params.devName = row.devName 
            // params.commName = row.commName 
            // params.agentName = row.agentName
            formData.id = row.Id
            formData.title = row.Name
            formData.description = row.Remark
            formData.product_restrict = row.DevName
            formData.combo_restrict = row.CommName
            formData.channel_restrict = row.AgentName
            formData.dialogVisible = true;

          }
        } catch (error) {

        }
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valTypes != -1) {
            params.openType = formData.valTypes
          }

          let res = await axios.get('/admin/couponlimit/get', {
            params
          })
          // console.log(JSON.stringify(res));
          formData.total = res.Total
          res.List.map((item) => {
            item.ComboName = item.Combo.Name
          })
          // console.log(JSON.stringify(res));

          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      },
      async deleteRule(id) {
        try {
          let res = await axios.post('/admin/couponlimit/delte', {
            id
          })
          if (res == '操作成功') {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
          } else {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }
          func.loadData()
        } catch (error) {
          ElMessage({
            type: 'error',
            message: t("err_code.20005")
          })
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      formData,
      render,
      token: user.token,
      isOperator,
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        // margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
      width: 500px;
    }

    .activity-content {
      flex: 2
    }

    .activity-style {
      flex: 1;
    }


  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    // margin-top: 0;
  }
}
</style>